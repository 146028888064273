<template>
  <transition name="alert-transition">
    <div v-if="!!data" class="alert" :class="{ 'is-desktop': display === 'desktop' , 'is-mobile': display === 'mobile' }">
      <div class="bg" @click="close"></div>
      <div class="alert-content" :class="[ 'is-bg-' + (data.bgColor || 'orange') ]">
        <img v-if="data.bgImg" :src="data.bgImg.src" :width="data.bgImg.width" :height="data.bgImg.height" :alt="data.bgImg.alt" class="alert-image">
        <h2 class="alert-title" v-html="data.title" :class="{ 'is-alone': !data.message }"></h2>
        <div v-if="data.message" class="alert-message">
          <p class="alert-message__important" v-html="data.message"></p>
          <p v-if="data.additional" class="alert-message__text">
            {{ data.additional }}
          </p>
        </div>
        <button @click="close" class="alert-close"></button>

        <img v-if="isWinAlert" src="../assets/svg/gift-win-alert.svg" class="win-alert-image" width="61" height="56" alt=""/>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'alert',
  props: {
    data: {
      type: Object,
      default: null
    },
    display: {
      type: String,
      default: null,
      validator (display) {
        return [null, 'desktop', 'mobile'].indexOf(display) > -1
      }
    }
  },
  computed: {
    isWinAlert () {
      return this.data.type === 'lottery-winner'
    }
  },
  methods: {
    close () {
      this.$store.commit('removeAlert')
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba($color: #000000, $alpha: 0.5);
  cursor: pointer;
}
.alert-content {
  width: 100%;
  text-align: center;
  padding: 110px 0 50px 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  color: #FFFFFF;
  position: relative;

  &.is-bg-orange {
    background-color: $orange;
  }

  &.is-bg-red {
    background-color: $red;
  }

  .alert-title {
    font-size: 32px;
    font-weight: 900;
    width: calc(100% - 130px);
    margin-left: auto;
    margin-right: auto;

    &.is-alone {
      margin-bottom: 0;
    }

    ::v-deep .light {
      font-weight: 400;
    }
  }

  .alert-image {
    position: absolute;
    right: 0;
    bottom: -3px;
    transform: translateX(25%);
  }

  .alert-message {
    font-size: 16px;
    margin: 0 auto;
    width: calc(100% - 130px);
    max-width: 300px;
    line-height: 1.4em;
    position: relative;

    .alert-message__important {
      font-weight: 700;
    }

    .alert-message__text {
      margin-top: 1em;
      font-weight: 400;
    }
  }
}
.alert-transition-enter,
.alert-transition-leave-active {
  opacity: 0;
  .alert-content {
    transform: translateY(-40px);
  }
}

.alert-transition-enter-active,
.alert-transition-leave-active {
  transition: opacity 0.5s ease;
  .alert-content {
    transition: transform 0.5s ease;
  }
}

.alert-close {
  display: block;
  width: 22px;
  height: 22px;
  position: absolute;
  top: 80px;
  right: 20px;
  background: none;
  border: 0;
  pointer-events: auto;
  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: #FFF;
    transform: translateY(-1px) rotate(-45deg);
  }
  &:after {
    transform: translateY(-1px) rotate(45deg);
  }
}

.alert.is-desktop {
  display: none;
  position: relative;
  width: 100%;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  pointer-events: none;
  .alert-content {
    display: flex;
    padding: 40px 90px;
    align-items: center;
    .alert-title {
      margin: 0 50px 0 0;
    }
  }
}

.win-alert-image {
  position: absolute;
  right: -40px;
  bottom: -1px;
  width: auto;
  height: 105px;
}

@include media('>=tablet') {
  .alert.is-desktop {
    display: block;
  }

  .alert.is-mobile {
    display: none;
  }

  .win-alert-image {
    position: absolute;
    right: -50px;
    bottom: -2px;
    width: auto;
    height: 150px;
  }
}

@include media('>=tablet') {
  .alert.is-desktop {
    display: block;
  }

  .alert.is-mobile {
    display: none;
  }
}
</style>
