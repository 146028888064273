import { lotteries as requestLotteries } from '../../../graphql-sdl/requests-clubist.graphql'
import { decodeLottery } from '@/pure/api-parser'
import ApiRequest from '@/pure/api-requests'

const apiRequest = new ApiRequest()

/** @type import('vuex/types').Module<any, any> */
const module = {
  namespaced: true,

  state: {
    latestLotteries: [],
    lotteryWinnerInfo: []
  },

  actions: {
    refreshLatestLotteries ({ commit }, { count = 3, isConfirmed = true }) {
      const payload = {
        lotteryFilter: {
          count,
          isConfirmed
        }
      }

      return apiRequest.query(requestLotteries, payload)
        .then(data => data.lotteries.map(decodeLottery))
        .then(data => {
          commit('updateLatestLotteries', data)
          return data
        })
    },

    refreshLotteryWinnerInfo ({ commit }, { clubistId, isConfirmed = true }) {
      const payload = {
        lotteryFilter: {
          clubistId,
          isConfirmed
        }
      }

      return apiRequest.query(requestLotteries, payload)
        .then(data => data.lotteries.map(decodeLottery))
        .then(data => {
          commit('updateLotteryWinnerInfo', data)
          return data
        })
    }
  },

  mutations: {
    updateLatestLotteries (state, latestLotteries) {
      state.latestLotteries = latestLotteries
    },

    removeLatestLotteries (state) {
      state.latestLotteries = []
    },

    updateLotteryWinnerInfo (state, lotteryWinnerInfo) {
      state.lotteryWinnerInfo = lotteryWinnerInfo
    },

    removeLotteryWinnerInfo (state) {
      state.lotteryWinnerInfo = []
    }
  },

  getters: {
    latestLotteries: state => state.latestLotteries,
    latestLottery: state => state.latestLotteries[0] || null,

    lotteryWinnerInfo: state => state.lotteryWinnerInfo,
    latestLotteryWinnerInfo: state => state.lotteryWinnerInfo[0] || null
  }
}

export default module
