<template>
  <span class="word-limit">{{ current }}/{{ max }}</span>
</template>

<script>

export default {
  props: {
    word: {
      type: String,
      default: ''
    },
    max: {
      type: Number,
      required: true
    }
  },
  computed: {
    current () {
      return this.word.length
    }
  }
}
</script>

<style lang="scss" scoped>
.word-limit {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0.7;
  margin-right: 0.5em;
}
</style>
