<template>
  <div class="jauge">

    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 375 188.8" style="enable-background:new 0 0 375 188.8;" xml:space="preserve">
    <g id="content">
      <path id="line-bg" class="st0" d="M185.7,188.8L319,172.4c0.1,0,0.2-0.1,0.2-0.2c-8.2-66.5-64.8-118-133.5-118
        c-68.8,0-125.6,51.7-133.6,118.4c0,0.1,0.1,0.2,0.2,0.2L185.7,188.8L185.7,188.8z"/>
      <path id="line-light" :transform="'rotate(' + lineLightRot + ' 186.5 189)'" class="st1" d="M186.7,187.6l181.5-22.3c0.2,0,0.3-0.2,0.3-0.3C357.3,74.5,280.2,4.5,186.7,4.5
        C93,4.5,15.8,74.8,4.9,165.6c0,0.2,0.1,0.3,0.3,0.3L186.7,187.6C186.6,187.6,186.7,187.6,186.7,187.6z"/>
      <path id="line-shadow" :transform="'rotate(' + lineShadowRot + ' 186.5 189)'" class="st2" d="M186.7,189l184-22.6c0.2,0,0.3-0.2,0.3-0.3C359.7,74.3,281.5,3.2,186.7,3.2
        c-95,0-173.3,71.4-184.3,163.5c0,0.2,0.1,0.3,0.3,0.3L186.7,189L186.7,189z"/>
      <path id="mask" class="st3" d="M-5.5-8.1v205.9H386V-8.1H-5.5z M278.9,176.9c-5.2-40.1-36.9-83.2-94.2-83.2h-0.1
        c-23,0-45,8.4-61.8,23.6c-16.9,15.2-27.7,36.3-30.4,59.4l-33.8-4c3.7-31.2,18.4-59.8,41.4-80.6c23.1-20.9,53.2-32.4,84.6-32.4h0.1
        c35.2,0,66.3,12.4,90,35.8c20.6,20.4,34.1,47.7,37.9,77L278.9,176.9z"/>
      <path id="arc" class="st4" d="M108.7,188.8c0-42,34-76,75.9-76.1s76,34,76.1,75.9L108.7,188.8z"/>
      <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 195.75 167.7532)" class="st1 st5 st6">dhs</text>
      <g id="states">
        <g :class="{active: debloque==100}">
          <line class="st7" x1="186.6" y1="36.8" x2="186.6" y2="87.8"/>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 170.87 16.1)" class="st8 st5 st9">100</text>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 175.88 28.8799)" class="st8 st5 st10">dhs</text>
        </g>
        <g :class="{active: debloque==150}">
          <line class="st7" x1="287.6" y1="75.7" x2="252.7" y2="112.7"/>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 295.9001 64)" class="st8 st5 st9">150</text>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 299.9201 76.79)" class="st8 st11 st10">dhs</text>
        </g>
        <g :class="{active: debloque==200}">
          <line class="st7" x1="279.5" y1="175.9" x2="334.4" y2="168.8"/>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 340.0451 167.9882)" class="st8 st5 st9">200</text>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 344.0651 180.7782)" class="st8 st11 st10">dhs</text>
        </g>
        <g :class="{active: debloque==50}">
          <line class="st7" x1="80.6" y1="67.8" x2="119.7" y2="111.8"/>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 48.14 60.19)" class="st8 st5 st9">50</text>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 47.92 72.97)" class="st8 st11 st10">dhs</text>
        </g>
        <g :class="{active: debloque==0}">
          <line class="st7" x1="84.1" y1="174.8" x2="29.2" y2="168.3"/>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 10.47 168.22)" class="st8 st5 st9">0</text>
          <text transform="matrix(1 -6.981316e-04 6.981316e-04 1 4.01 181.01)" class="st8 st11 st10">dhs</text>
        </g>
      </g>
      <g id="arrow" :transform="'rotate(' + arrowRot + ' 185.5 189.5)'">
        <polygon class="st12" points="111.4,179.8 110.1,188.9 72.6,174.4"/>
        <polygon class="st13" points="112.6,170.7 111.4,179.8 72.6,174.4"/>
      </g>
      <rect x="190.5" y="146.4" class="st14" width="162.5" height="42.5"/>
      <text id="counter" transform="matrix(1 0 0 1 190.5 168.0607)" class="st1 st5 st15">{{count}}</text>
    </g>
    <g id="layout" class="st16">
      <line class="st17" x1="186.7" y1="189" x2="186.7" y2="-75.5"/>
      <line class="st17" x1="187.2" y1="189" x2="-75.1" y2="154.9"/>
      <line class="st17" x1="186.7" y1="189" x2="12.2" y2="-9.7"/>
      <line class="st17" x1="186.7" y1="189" x2="361.3" y2="-9.7"/>
      <line class="st17" x1="186.7" y1="189" x2="449" y2="154.9"/>
    </g>
    </svg>
  </div>
</template>

<script>
import anime from 'animejs'

const jaugeRotationStart = 0
const jaugeRotationEnd = 165.2
const lineRotationStart = -jaugeRotationEnd
const lineRotationEnd = 0

export default {
  props: {
    cashback: {
      type: Number
    }
  },
  data () {
    return {
      count: 0,
      lineShadowRot: lineRotationStart,
      lineLightRot: lineRotationStart,
      arrowRot: jaugeRotationStart
    }
  },
  computed: {
    cashbackClean () {
      return Math.max(0, Math.min(200, this.cashback))
    },
    debloque () {
      return Math.max(0, Math.min(200, (Math.floor(this.cashback / 50) * 50)))
    }
  },
  watch: {
    cashback (cashback) {
      this.startAnimation()
    }
  },
  mounted () {
    this.createIntersectionObserver()
  },

  destroyed () {
    this.destroyIntersectionObserver()
  },

  methods: {

    createIntersectionObserver () {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
      }

      this.observer = new IntersectionObserver(this.checkScreen, options)
      this.observer.observe(this.$el)
    },

    destroyIntersectionObserver () {
      if (this.observer) {
        this.observer.unobserve(this.$el)
        this.observer.disconnect()
        delete this.observer
      }
    },

    checkScreen (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.intersectionRatio > 0.3) {
            this.destroyIntersectionObserver()
            this.startAnimation()
          }
        }
      })
    },

    startAnimation () {
      this.destroyIntersectionObserver()

      anime()
      anime.timeline()
        .add({
          targets: this.$data,
          count: 200,
          round: 1,
          easing: 'easeInCirc'
        })
        .add({
          targets: this.$data,
          arrowRot: jaugeRotationEnd,
          easing: 'easeInCirc'
        }, 0)
        .add({
          targets: this.$data,
          lineLightRot: lineRotationEnd,
          easing: 'easeInCirc'
        }, 0)
        .add({
          targets: this.$data,
          lineShadowRot: lineRotationEnd,
          easing: 'easeInCirc'
        }, 0)
        .add({
          targets: this.$data,
          count: this.cashback,
          round: 1,
          easing: 'easeOutCirc'
        })
        .add({
          targets: this.$data,
          lineLightRot: -jaugeRotationEnd + ((jaugeRotationStart + jaugeRotationEnd) / 200) * this.cashbackClean,
          easing: 'easeOutCirc'
        }, '-=1000')
        .add({
          targets: this.$data,
          lineShadowRot: -jaugeRotationEnd + ((jaugeRotationStart + jaugeRotationEnd) / 200) * this.debloque,
          easing: 'easeOutCirc'
        }, '-=1000')
        .add({
          targets: this.$data,
          arrowRot: jaugeRotationStart + ((jaugeRotationEnd - jaugeRotationStart) / 200) * this.cashbackClean,
          easing: 'easeOutCirc'
        }, '-=1000')
    }
  }
}
</script>

<style lang="scss" scoped>
.jauge {
  padding: 15px;

  svg {
    width: 100%;
    overflow: hidden; // IE11 fix
    text {
      font-family: "Roboto";
      font-weight: 700;
    }
  }

  #states {
    .active {
      text,
      line {
        opacity: 1;
      }
    }
  }

  #counter {
    text-anchor: end;
  }
}

// From SVG
.st0{fill:#E4E7ED;}
.st1{fill:#164194;}
.st2{fill:#4A96D2;}
.st3{fill:#FFFFFF;}
.st4{fill:#F5F7FA;}
.st5{font-family:'Roboto';}
.st6{font-size:20.7px;}
.st7{opacity:0.5;fill:none;stroke:#5477BB;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;enable-background:new    ;}
.st8{opacity:0.5;fill:#4A96D2;enable-background:new    ;}
.st9{font-size:20px;}
.st10{font-size:14.84px;}
.st11{font-family:'Roboto';}
.st12{fill:#42474B;}
.st13{fill:#2A2E31;}
.st14{fill:none;}
.st15{font-size:30.11px;}
.st16{display:none;}
.st17{display:inline;fill:none;stroke:#174194;stroke-miterlimit:10;}
</style>
