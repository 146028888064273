<template>
  <header class="site-header">
    <div class="site-header__logo">
      <router-link :to="home">
        <img class="site-header__img" width="90" height="70" src="@/assets/images/total-energies-logo.svg" alt="TOTAL Energies" />
      </router-link>
    </div>
    <div
      v-if="$route.meta && $route.meta.requiresAuth"
      id="nav-icon"
      @click="toggleMenu"
      :class="{ open: menuOpened }"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </header>
</template>

<script>
export default {
  props: ['menuOpened'],
  computed: {
    home () {
      return this.$store.getters.isLogged ? { name: 'dashboard' } : { name: 'landing' }
    }
  },
  methods: {
    toggleMenu () {
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  flex-shrink: 0;
  background-color: #fff;
  padding: 0 25px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 20;
  &__logo {
    img {
      vertical-align: middle;
    }
  }
}
#nav-icon {
  width: 20px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 20px;
    background: $red;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }
    &:nth-child(4) {
      top: 18px;
    }
  }
  &.open {
    span {
      &:nth-child(1) {
        transform: translateY(9px);
        opacity: 0;
      }
      &:nth-child(2) {
        transform: rotate(45deg) scaleX(1.2);
      }
      &:nth-child(3) {
        transform: rotate(-45deg) scaleX(1.2);
      }
      &:nth-child(4) {
        transform: translateY(-9px);
        opacity: 0;
      }
    }
  }
  @include media('>large') {
    display: none;
  }
}
</style>
