<template>
  <section class="explanations">
    <div class="contain">
      <h2 v-html="$t('winDhmsExplanation.title')"></h2>
      <div class="vehicle" :style="{transform: 'translateX(' + distanceVehicle + 'px)'}">
        <img v-if="isTaxi" src="../assets/svg/profile-taxi.svg" alt="taxi" width="122" height="66" />
        <img v-else-if="isCar" src="../assets/svg/profile-auto.svg" alt="voiture" width="107" height="60" />
        <img v-else src="../assets/svg/profile-pro.svg" alt="professionnel" width="94" height="59" />
      </div>
      <swiper :options="swiperOption" ref="explanationSwiper" @slideChange="slideChange">
        <swiper-slide>
          <div class="explanation">
            <span class="index">1</span>
            <div>
              <h3 v-html="$t('winDhmsExplanation.part1')"></h3>
              <h4 v-html="$t('winDhmsExplanation.part1booster')"></h4>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="explanation">
            <span class="index">2</span>
            <div class="right">
              <h3 v-html="$t('winDhmsExplanation.part2')"></h3>
              <img src="@/assets/images/club-card.png" srcset="@/assets/images/club-card-2x.png 2x" width="131" height="91" class="total-club-card" alt="Carte TotalEnergies Club" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="explanation">
            <span class="index">3</span>
            <div class="right">
              <h3 v-html="$t('winDhmsExplanation.part3')"></h3>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="explanation">
            <span class="index">4</span>
            <div class="right">
              <h3 v-html="$t('winDhmsExplanation.part4')"></h3>
              <img src="@/assets/images/prepaid-card.png"  srcset="@/assets/images/prepaid-card-2x.png 2x" width="158" height="121" class="total-club-card" alt="Carte TotalEnergies Club" />
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="explanation">
            <span class="index">5</span>
            <div class="right">
              <h3 v-html="$t('winDhmsExplanation.part5')"></h3>
            </div>
          </div>
        </swiper-slide>

      </swiper>
    </div>
  </section>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      swiperOption: {
        setWrapperSize: true,
        resistanceRatio: 0,
        slidesPerView: 5,
        breakpoints: {
          // when window width is <=
          767: {
            slidesPerView: 1
          }
        }
      },
      distanceVehicle: 0
    }
  },
  components: {
    swiper,
    swiperSlide
  },
  computed: {
    swiper () {
      return this.$refs.explanationSwiper.swiper
    },
    ...mapGetters([
      'isCar',
      'isTaxi'
    ])
  },
  methods: {
    slideChange () {
      this.distanceVehicle = document.querySelector('.explanations').offsetWidth / this.swiper.slides.length * this.swiper.activeIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.next {
  opacity: 0.6;
}
.explanations {
  position: relative;
  overflow: hidden;
  background-color: $dark-red;
  color: #fff;
  @include media('>=tablet') {
    margin: 0;
    padding: 25px 25px 100px;
    background-size: cover;
    background-image: url('~@/assets/svg/road-bg-desktop.png');
    background-position: bottom center;
  }
  @include media('>desktop') {
    padding: 25px 50px 100px;
  }
  h2 {
    margin: 20px;
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    background-color: #fff;
    color: $dark-red;
    padding: 15px 25px;
    position: relative;
    text-transform: uppercase;
    @include media('>=tablet') {
      font-size: 24px;
      padding: 15px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 1px solid white;
      transform: translate(-10px, -10px);
    }
  }
  .explanation {
    display: flex;
    padding: 20px 0 100px 20px;
    @include media('>=tablet') {
      padding: 20px;
      align-items: center;
    }
    .index {
      font-size: 126px;
      font-weight: 800;
      font-style: italic;
      text-shadow: -5px -5px rgba($color: #ffffff, $alpha: 0.3);
      @include media('>=tablet') {
        font-size: 100px;
        line-height: 70px;
      }
    }
    h3 {
      margin: 20px 0 20px 40px;
      @include media('>=tablet') {
        margin: 0 0 0 30px;
      }
    }
    h4 {
      margin: 40px 15px 0;
      padding: 10px;
      background: #fff;
      color: #ba0a2c;
    }
    .right {
      @include media('>desktop') {
        display: flex;
        flex-direction: row-reverse;
      }
      .total-club-card {
        margin: 0 0 0 calc(50% - 50px);
        align-self: center;

        @include media('>=tablet') {
          margin: 20px 0 0 calc(50% - 25px);
        }

        @include media('>desktop') {
          margin: 0 0 0 50px;
        }
      }
    }
  }
  .vehicle {
    position: absolute;
    display: block;
    z-index: 10;
    bottom: 20px;
    transition: transform 300ms ease-in-out;
    pointer-events: none;
    img {
      animation: vehicle 200ms ease-in infinite;
      @include media('>tablet') {
        animation: none;
      }
    }
  }
  @keyframes vehicle {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(2px);
    }
  }
}
.soon {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 0.2em;
}
</style>

<style lang="scss">
.explanations {
  .swiper-container {
    @include media('<tablet') {
      width: 80%;
      overflow: visible;
      margin-left: 5%;
      margin-right: 15%;
    }
  }
  .swiper-wrapper {
    // position: absolute;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -5vw;
      right: -15vw;
      bottom: 0;
      background-size: 100% auto;
      background-image: url('~@/assets/svg/road-bg.png');
      background-position: bottom left;
      background-repeat: repeat-x;
    }

    @include media('>=tablet') {
      display: flex;
      flex-direction: column;
      background-image: none;

      &:before {
        display: none;
      }
    }
  }
  .swiper-slide {
    @include media('>=tablet') {
      width: auto !important;
      &:nth-child(even) {
        .explanation {
          flex-direction: row-reverse;
          h3 {
            text-align: right;
            margin-right: 25px;
          }
        }
      }
    }
  }
}
</style>
