/**
 * Extend it to add dispatcher events support to your class:
 * @example
 * class MyClass extends EventEmitter {
 *   test () {
 *     this.emit('say', 'Hello world')
 *   }
 * }
 *
 * const display = data => alert(data)
 * const myClass = new MyClass()
 * myClass.on('say', window.alert)
 * myClass.test()
 * myClass.off('say', window.alert)
 */
export default class EventEmitter {
  constructor () {
    this._emitterCallbacks = { }
  }

  /**
   * Dispatch data to all callbacks attached to the event
   *
   * @param {String} eventName  Name of the event
   * @param {...any} data       Optional data
   */
  emit (eventName, ...data) {
    if (this._emitterCallbacks[eventName]) {
      this._emitterCallbacks[eventName].forEach(fct => {
        fct(...data)
      })
    }
  }

  /**
   * Attach a callback to listen an event
   *
   * @param {String} eventName    Name of the event
   * @param {Function} callback   Called on this event triggered
   */
  on (eventName, callback) {
    if (!this._emitterCallbacks[eventName]) {
      this._emitterCallbacks[eventName] = []
    }
    this._emitterCallbacks[eventName].push(callback)
  }

  /**
   * Remove the callback listen an event
   *
   * @param {String} eventName    Name of the event
   * @param {Function} callback   Called on this event triggered
   */
  off (eventName, callback) {
    let i = this._emitterCallbacks[eventName] ? this._emitterCallbacks[eventName].indexOf(callback) : -1
    while (i > -1) {
      this._emitterCallbacks[eventName].splice(i, 1)
      i = this._emitterCallbacks[eventName] ? this._emitterCallbacks[eventName].indexOf(callback) : -1
    }
  }
}
