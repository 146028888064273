<template>
  <div class="sav-block">
    <p class="title">Besoin d'assistance ?</p>

    <ContactLinkLarge />
  </div>
</template>

<script>
import ContactLinkLarge from '@/components/ContactLinkLarge.vue'

export default {
  name: 'SAVBlock',

  components: {
    ContactLinkLarge
  }
}
</script>

<style lang="scss" scoped>
.sav-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 16px;
  background-color: #fff;

  // Overide ContactLinkLarge style
  .contact-link-large {
    display: block;
    padding: 8px 16px;
    width: 250px;
    min-width: 250px;
    ::v-deep p {
      font-size: 16px;
    }
    ::v-deep strong {
      font-size: 20px;
    }
  }

  @include media(">desktop") {
    order: 4;
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
}

.title {
  margin: 16px 0;
  padding: 0 16px;
  font-size: 16px;
  color: $red;
  font-weight: 600;
  text-align: center;

  @include media('>desktop') {
    width: 100%;
  }
}
</style>
