<template>
  <section class="section-concours" :class="{ 'is-disallowed': !isContestEnabled }">
    <div class="content">
      <div class="concours-flag">
        <div class="content-flag">
          <div class="concours-date">du {{ begin }} au {{ end }}</div>
          <img src="../assets/svg/contest-flag.svg" alt="1 an de carburant à gagner chaque semaine" width="366" height="246" />
        </div>
      </div>

      <div class="concours-countdown">
        <h2 v-if="isWinAllowed">
          Vous êtes éligible
          au tirage au sort
        </h2>
        <p>Prochain tirage dans</p>
        <div class="coundown">
          <div class="coundown-days">
            <span>{{ counter.days[0] }}</span>
            <span>{{ counter.days[1] }}</span>
            <div class="coundown-legend">Jours</div>
          </div>
          <div class="coundown-hours">
            <span>{{ counter.hours[0] }}</span>
            <span>{{ counter.hours[1] }}</span>
            <div class="coundown-legend">Heures</div>
          </div>
          <div class="coundown-minutes">
            <span>{{ counter.minutes[0] }}</span>
            <span>{{ counter.minutes[1] }}</span>
            <div class="coundown-legend">Minutes</div>
          </div>
        </div>
      </div>
    </div>

    <div class="infos">

      <button @click="$eventHub.$emit('modal-win-allowed-info')" class="btn btn-underline small-text">
        Qui est éligible au TAS?
      </button>
      <button @click="$eventHub.$emit('modal-rules')" class="btn btn-underline small-text">
        Règlement
      </button>
      <button @click="$eventHub.$emit('modal-latest-winners')" class="btn btn-underline small-text">
        Consulter la liste des précédents gagnants
      </button>

    </div>

    <figure v-if="!isContestEnabled" class="over">
      <figcaption>
        <div class="concours-title">Bientôt</div>
        <div class="concours-tagline">Tentez votre chance au tirage au sort</div>
        <button @click="$eventHub.$emit('modal-win-allowed-info')" class="btn btn-underline concours-info-link small-text">
          Qui est éligible au TAS?
        </button>
      </figcaption>
    </figure>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { getStartCurrentLotteryDate, getEndCurrentLotteryDate, getLotteryDate } from '@/pure/dates.js'

export default {
  name: 'concours',
  data () {
    return {
      isContestEnabled: true,
      counter: this.getCounter()
    }
  },
  computed: {
    ...mapGetters([
      'isWinAllowed'
    ]),
    begin () {
      return getStartCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    },
    end () {
      return getEndCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    }
  },
  mounted () {
    this.setCounter()
  },
  destroyed () {
    clearTimeout(this.to)
  },
  methods: {
    setCounter () {
      this.counter = this.getCounter()

      clearTimeout(this.to)

      // Loop every minutes
      this.to = setTimeout(this.setCounter, this.getDelayChangeTime() + 10)
    },
    getDelayChangeTime () {
      const now = new Date(Date.now() + this.$store.getters.clientDelay)
      const allMinutes = (getLotteryDate(now).getTime() - now.getTime()) / (1000 * 60)

      return (allMinutes - Math.floor(allMinutes)) * 1000 * 60
    },
    getCounter () {
      const now = new Date(Date.now() + this.$store.getters.clientDelay)
      const allMinutes = (getLotteryDate(now).getTime() - now.getTime()) / (1000 * 60) + 1

      const days = Math.floor(allMinutes / (60 * 24))
      const hours = Math.floor((allMinutes / 60) - days * 24)
      const minutes = Math.floor((allMinutes - (days * 24 * 60 + hours * 60)))

      return {
        days: ('0' + days).slice(-2),
        hours: ('0' + hours).slice(-2),
        minutes: ('0' + minutes).slice(-2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-concours {
  margin: 0;
  background-color: $orange;
  color: #fff;
  position: relative;
  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
  }
  .over {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(darken($orange, 10%), 0.5);
  }
  &.is-disallowed {
    .content {
      filter: blur(4px);
    }
    .infos {
      visibility: hidden;
    }
  }
  .concours-flag {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    flex: 5;
    min-width: 200px;
    position: relative;
    &:before {
      content: "";
      position: relative;
      display: block;
      padding-top: (100% * 2 / 3)
    }
    .content-flag {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .concours-date {
    font-size: 16px;
    margin-bottom: -2em;
  }
  .concours-countdown {
    border: 2px solid #fff;
    padding: 20px 10px;
    text-align: center;
    flex: 4;
    min-width: 200px;
    h2 {
      font-size: 22px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .coundown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      & > div {
        margin: 5px;
      }
      span {
        display: inline-block;
        font-size: 29px;
        font-weight: 800;
        color: $orange;
        background-color: #fff;
        border-radius: 2px;
        padding: 2px;
        margin: 0 2px 10px 2px;
        position: relative;
        &:after {
          content: '';
          background-color: $orange;
          display: block;
          height: 2px;
          position: absolute;
          top: calc(50% - 1px);
          left: 0;
          right: 0;
        }
      }
    }
  }
  figure {
    position: relative;
    img {
      display: block;
    }
    figcaption {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .concours-title {
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
  }
  .concours-tagline {
    font-size: 20px;
  }
  .concours-info-link {
    position: absolute;
    left: auto;
    right: auto;
    width: 100%;
    bottom: 2em;
  }
}
.small-text {
  text-transform: none;
  color: white;
  font-weight: 400;
  font-size: 18px;
  display: inline-block;
  width: auto;
}
.infos {
  text-align: center;
  width: 100%;
  padding-bottom: 1em;
  .small-text {
    font-size: 16px;

    &:after {
      content: "|";
      text-decoration: none;
      margin: 0 0.5em;
      pointer-events: none;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
}
</style>
