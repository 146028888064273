<template>
  <div>
    <Modal :show="isModalPrepaidOpen" @close="isModalPrepaidOpen = false">
      <Prepaid
        @close="isModalPrepaidOpen = false"
        @submit-success="
          $store.commit('addAlert', {
            title: $t('prepaid.success.title'),
            message: $t('prepaid.success.message')
          })"
        @open-already-prepaid="isModalAlreadyPrepaidOpen = true"
      />
    </Modal>

    <Modal :show="isModalPrepaidAndCashbackUnlockOpen" @close="isModalPrepaidAndCashbackUnlockOpen = false">
      <Prepaid
        @close="isModalPrepaidAndCashbackUnlockOpen = false"
        @submit-success="
          $store.commit('addAlert', {
            title: $t('prepaidAndCashbackUnlock.success.title'),
            message: $t('prepaidAndCashbackUnlock.success.message')
          })"
        @open-already-prepaid="isModalAlreadyPrepaidOpen = true"
      />
    </Modal>

    <Modal :show="isModalAlreadyPrepaidOpen" @close="isModalAlreadyPrepaidOpen = false">
      <h2>Obtenir une TotalEnergies Card prépayée</h2>
      <p class="text-content">
        Veuillez contacter le Service Client au <span v-html="totalPhone"></span> pour commander une nouvelle TotalEnergies Card prépayée.
      </p>
      <button class="btn btn-inverse" @click="isModalAlreadyPrepaidOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isBanned" @close="$store.dispatch('logout')">
      <h2>Statut: Bloqué</h2>
      <p>
        Vous n’êtes plus en mesure
        de cumuler des dirhams ni
        de retirer votre cagnotte.
      </p>
      <ContactLinkLarge class="contact-block" />
      <button class="btn btn-simple" @click="$store.dispatch('logout')">Annuler</button>
    </Modal>

    <Modal :show="modalPrepaidCardConfirmNum !== null" @close="modalPrepaidCardConfirmNum = null">
      <h2>Confirmez-vous votre numéro de Carte Prépayée ?</h2>
      <strong class="large">{{ formatNum(modalPrepaidCardConfirmNum) }}</strong>
      <p>
        Une fois le numéro de votre carte Prépayée confirmé,<br>
        vous ne pourrez plus le modifier.
      </p>
      <button class="btn btn-inverse" @click="confirmPrepaidCardNum(modalPrepaidCardConfirmNum)">Confirmer</button>
      <button class="btn btn-simple" @click="modalPrepaidCardConfirmNum = null">Annuler</button>
    </Modal>

    <Modal :show="isModalStatusAlertOpen" @close="isModalStatusAlertOpen = false">
      <h2>Statut: Averti</h2>
      <p>
        Vous êtes toujours en mesure
        de cumuler des dirhams lors de vos transactions en station mais vous
        ne pouvez plus retirer votre cagnotte.
      </p>
      <p>
        <strong>
          Contactez le Service Client
          pour réactiver votre compte.
        </strong>
      </p>
      <ContactLinkLarge class="contact-block" />
      <button class="btn btn-simple" @click="isModalStatusAlertOpen = false">Annuler</button>
    </Modal>

    <Modal :show="isModalCardOpen" @close="isModalCardOpen = false">
      <img src="../assets/images/card-information.jpg" alt="informations" width="256" height="181" />
      <p v-html="$t('modals.clubCard.helpNum.resume')"></p>
      <button class="btn btn-inverse" @click="isModalCardOpen = false" v-html="$t('modals.clubCard.helpNum.ok')"></button>
    </Modal>

    <Modal :show="isModalWinAllowedInfoOpen" @close="isModalWinAllowedInfoOpen = false">
      <h2>Eligibilité au tirage au sort</h2>
      <p>
        Vous êtes éligible au tirage au sort
        si vous avez réalisé au moins une transaction de carburant de 15L
        dans la semaine
      </p>
      <button class="btn btn-inverse" @click="isModalWinAllowedInfoOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isModalLatestWinnersOpen" @close="isModalLatestWinnersOpen = false">
      <h2>Gagnants des précédents tirages au sort</h2>

      <template v-if="latestLotteries">
        <div v-for="(lottery, i) in latestLotteries" :key="`lottery-${i}`" class="winner-container">
          <h3 class="winner-name" v-if="lottery.clubist">{{ lottery.clubist.firstName }} {{ lottery.clubist.lastName }}</h3>
          <h4 class="winner-date">Semaine du {{ new Date(lottery.startDate).toLocaleDateString('fr-FR') }}</h4>
        </div>
      </template>

      <button class="btn btn-inverse" @click="isModalLatestWinnersOpen = false">J'ai pris connaissance</button>
    </Modal>

    <Modal :show="isModalEditPasswordOpen" @close="isModalEditPasswordOpen = false">
      <p>
        Pour modifier votre mot de passe,<br>
        veuillez contactez le Service Client
      </p>
      <ContactLinkLarge class="contact-block" />
      <button class="btn btn-simple" @click="isModalEditPasswordOpen = false">Annuler</button>
    </Modal>

    <Modal :show="isModalLegalsOpen" @close="isModalLegalsOpen = false">
      <Legals />
      <button class="btn btn-simple" @click="isModalLegalsOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isModalConditionsOpen" @close="isModalConditionsOpen = false">
      <Conditions />
      <button class="btn btn-simple" @click="isModalConditionsOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isModalRulesOpen" @close="isModalRulesOpen = false">
      <Rules />
      <button class="btn btn-simple" @click="isModalRulesOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isModalNoPrepaidCardOpen" @close="isModalNoPrepaidCardOpen = false">
      <h2>Aucune TotalEnergies Card prépayée associée</h2>
      <p>
        Une TotalEnergies Card prépayée est nécessaire au déblocage de vos dirhams.
      </p>

      <button
        v-if="false"
        class="btn btn-inverse"
        @click="isModalNoPrepaidCardOpen = false; $eventHub.$emit('modal-set-prepaid-card')"
      >J’ai une TotalEnergies Card prépayée</button>
      <button
        v-if="hasAlreadyClaimPrepaidCard"
        class="btn btn-inverse"
        @click="isModalNoPrepaidCardOpen = false; $eventHub.$emit('modal-already-prepaid')"
      >
        Je commande ma TotalEnergies&nbsp;Card&nbsp;prépayée
      </button>
      <button
        v-else
        class="btn btn-inverse"
        @click="isModalNoPrepaidCardOpen = false; $eventHub.$emit('modal-prepaid-and-cashback-unlock')"
      >
        Je commande ma TotalEnergies&nbsp;Card&nbsp;prépayée
      </button>

      <button class="btn btn-simple" @click="isModalNoPrepaidCardOpen = false">Annuler</button>
    </Modal>

    <Modal :show="isModalNoSecondaryPrepaidCardOpen" @close="isModalNoSecondaryPrepaidCardOpen = false">
      <h2>Obtenir une TotalEnergies Card prépayée</h2>

      <p>
        Veuillez contacter le <b>Service Client au 0801 000 023</b> pour commander une nouvelle TotalEnergies Card prépayée.
      </p>

      <button class="btn btn-inverse" @click="isModalNoSecondaryPrepaidCardOpen = false">J'ai compris</button>
    </Modal>

    <Modal :show="isModalSetPrepaidCardOpen" @close="isModalSetPrepaidCardOpen = false">
      <div v-loading="isLoading" class="width-limit">
        <h2>Renseigner votre TotalEnergies Card prépayée</h2>
        <p>
          Veuillez saisir le N° de votre TotalEnergies Card prépayée :
        </p>

        <ValidationObserver ref="prepaidCard">
          <ValidationProvider rules="required|numeric|max:8|min:1" v-slot="{ errors }" name="TotalEnergies Card prépayée">
            <input v-model="prepaidCardNumInput" type="text" class="input-text" maxlength="8" />
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
        </ValidationObserver>

        <p>
          Une fois le numéro de TotalEnergies Card prépayée confirmé, vous ne pourrez plus le modifier.
        </p>

        <button class="btn btn-inverse" @click="submitPrepaidCardNum()">Confirmer</button>
        <button class="btn btn-simple" @click="isModalSetPrepaidCardOpen = false">Annuler</button>
      </div>
    </Modal>

    <Modal v-loading="isLoading" :show="isModalCashbackUnlockFromPrepaidOpen" @close="isModalCashbackUnlockFromPrepaidOpen = false">
      <h2>Débloquer les Dirhams</h2>
      <p>
        La cagnotte sera transférée sur la TotalEnergies Card prépayée suivante :
      </p>
      <strong class="large">{{ formatNum(prepaidCardNum) }}</strong>
      <button class="btn btn-inverse" @click="claimUnlockCashbackFromPrepaid()">Débloquer {{ dirhamsToUnlock }} DHS</button>
      <button class="btn btn-simple" @click="isModalCashbackUnlockFromPrepaidOpen = false">Annuler</button>
    </Modal>

    <Modal v-loading="isLoading" :show="isModalCashbackUnlockOpen" @close="isModalCashbackUnlockOpen = false">
      <h2>Débloquer les Dirhams</h2>
      <p>
        La cagnotte sera transférée sur la TotalEnergies Card prépayée suivante :
      </p>
      <strong class="large">{{ formatNum(prepaidCardNum) }}</strong>
      <button class="btn btn-inverse" @click="claimUnlockCashback()">Débloquer {{ dirhamsToUnlock }} DHS</button>
      <button class="btn btn-simple" @click="isModalCashbackUnlockOpen = false">Annuler</button>
      <p class="footer">
        Cette carte n’est pas la vôtre ?<br>
        <router-link :to="{ name: 'settings-support' }" @click.native="isModalCashbackUnlockOpen = false" class="btn btn-underline link">Gérer les cartes</router-link>
      </p>
    </Modal>

    <!-- Last child because need to be over the other modals -->
    <Modal :show="isModalPhoneOpen" @close="isModalPhoneOpen = false">
      <p class="text-lg">
        Contactez le Service Client au :<br>
        <span class="text-xl" v-html="totalPhone"></span>
      </p>
      <button class="btn btn-simple" @click="isModalPhoneOpen = false">Annuler</button>
    </Modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { config } from '@/config.js'
import Modal from '@/components/Modal.vue'
import Prepaid from '@/components/Prepaid.vue'
import ContactLinkLarge from '@/components/ContactLinkLarge.vue'

export default {
  data () {
    return {
      emailTotal: config.contact.email,
      totalPhone: config.contact.phone.split(' ').join('&nbsp;'),

      isModalPhoneOpen: false,
      isModalPrepaidOpen: false,
      isModalPrepaidAndCashbackUnlockOpen: false,
      isModalAlreadyPrepaidOpen: false,
      isModalCardOpen: false,
      isModalWinAllowedInfoOpen: false,
      isModalLatestWinnersOpen: false,
      isModalEditPasswordOpen: false,
      isModalLegalsOpen: false,
      isModalRulesOpen: false,
      isModalConditionsOpen: false,
      isModalNoPrepaidCardOpen: false,
      isModalNoSecondaryPrepaidCardOpen: false,
      isModalSetPrepaidCardOpen: false,
      isModalCashbackUnlockFromPrepaidOpen: false,
      isModalCashbackUnlockOpen: false,
      isModalStatusAlertOpen: false,

      modalPrepaidCardConfirmNum: null,

      prepaidCardNumInput: '',

      isLoading: false
    }
  },
  components: {
    Modal,
    Prepaid,
    ContactLinkLarge,
    ValidationObserver,
    ValidationProvider,
    Legals: () => import(/* webpackChunkName: "documents" */ '@/components/large-text/Legals'),
    Conditions: () => import(/* webpackChunkName: "documents" */ '@/components/large-text/Conditions'),
    Rules: () => import(/* webpackChunkName: "documents" */ '@/components/large-text/Rules')
  },
  computed: {
    ...mapGetters([
      'isBanned',
      'isWarned',
      'prepaidCardNum',
      'dirhamsToUnlock',
      'hasAlreadyClaimPrepaidCard'
    ]),

    ...mapGetters({
      'latestLotteries': 'lottery/latestLotteries'
    })
  },
  created () {
    this.$eventHub.$on('modal-phone', this.toggleModalPhone)
    this.$eventHub.$on('modal-prepaid', this.toggleModalPrepaid)
    this.$eventHub.$on('modal-prepaid-and-cashback-unlock', this.toggleModalPrepaidAndCashbackUnlock)
    this.$eventHub.$on('modal-already-prepaid', this.toggleModalAlreadyPrepaid)
    this.$eventHub.$on('modal-card', this.toggleModalCard)
    this.$eventHub.$on('modal-win-allowed-info', this.toggleModalWinAllowedInfo)
    this.$eventHub.$on('modal-latest-winners', this.toggleModalLatestWinners)
    this.$eventHub.$on('modal-prepaid-card-confirm', this.setModalPrepaidCardConfirm)
    this.$eventHub.$on('modal-edit-password', this.setModalEditPassword)
    this.$eventHub.$on('modal-legals', this.toggleModalLegals)
    this.$eventHub.$on('modal-conditions', this.toggleModalConditions)
    this.$eventHub.$on('modal-rules', this.toggleModalRules)
    this.$eventHub.$on('modal-no-prepaid-card', this.toggleModalNoPrepaidCard)
    this.$eventHub.$on('modal-no-secondary-prepaid-card', this.toggleModalNoSecondaryPrepaidCard)
    this.$eventHub.$on('modal-set-prepaid-card', this.toggleModalSetPrepaidCard)
    this.$eventHub.$on('modal-cashback-unlock-from-prepaid', this.toggleModalCashbackUnlockFromPrepaid)
    this.$eventHub.$on('modal-cashback-unlock', this.toggleModalCashbackUnlock)
    this.$eventHub.$on('modal-status-alert', this.toggleStatusAlert)
  },
  destroyed () {
    this.$eventHub.$off('modal-phone', this.toggleModalPhone)
    this.$eventHub.$off('modal-prepaid', this.toggleModalPrepaid)
    this.$eventHub.$off('modal-prepaid-and-cashback-unlock', this.toggleModalPrepaidAndCashbackUnlock)
    this.$eventHub.$off('modal-already-prepaid', this.toggleModalAlreadyPrepaid)
    this.$eventHub.$off('modal-card', this.toggleModalCard)
    this.$eventHub.$off('modal-win-allowed-info', this.toggleModalWinAllowedInfo)
    this.$eventHub.$off('modal-latest-winners', this.toggleModalLatestWinners)
    this.$eventHub.$off('modal-prepaid-card-confirm', this.setModalPrepaidCardConfirm)
    this.$eventHub.$off('modal-edit-password', this.setModalEditPassword)
    this.$eventHub.$off('modal-legals', this.toggleModalLegals)
    this.$eventHub.$off('modal-conditions', this.toggleModalConditions)
    this.$eventHub.$off('modal-rules', this.toggleModalRules)
    this.$eventHub.$off('modal-no-prepaid-card', this.toggleModalNoPrepaidCard)
    this.$eventHub.$off('modal-no-secondary-prepaid-card', this.toggleModalNoSecondaryPrepaidCard)
    this.$eventHub.$off('modal-set-prepaid-card', this.toggleModalSetPrepaidCard)
    this.$eventHub.$off('modal-cashback-unlock-from-prepaid', this.toggleModalCashbackUnlockFromPrepaid)
    this.$eventHub.$off('modal-cashback-unlock', this.toggleModalCashbackUnlock)
    this.$eventHub.$off('modal-status-alert', this.toggleStatusAlert)
  },
  methods: {
    formatNum (num) {
      return String(num).split('').reverse().map((c, i) => i > 0 && i % 3 === 2 ? ' ' + c : c).reverse().join('')
    },

    claimUnlockCashback () {
      this.isLoading = true
      return this.$store.dispatch('claimUnlockCashback')
        .then(() => {
          this.$store.commit('addAlert', {
            title: 'Merci !',
            message: 'Votre demande de déblockage de cashback a bien été prise en compte'
          })
        })
        .catch(error => {
          this.$store.commit('addAlert', {
            title: 'Une erreur est survenue',
            message: this.$t(error.message),
            bgColor: 'red'
          })
        })
        .finally(() => {
          this.isLoading = false
          this.isModalCashbackUnlockOpen = false
        })
    },

    claimUnlockCashbackFromPrepaid () {
      this.isLoading = true
      return this.$store.dispatch('claimUnlockCashback')
        .then(() => {
          this.$store.commit('addAlert', {
            title: 'Merci !',
            message: 'Votre TotalEnergies Card prépayée est désormais associée à votre profil.<br>Votre demande de déblocage de dirhams a bien été prise en compte.'
          })
        })
        .catch(error => {
          this.$store.commit('addAlert', {
            title: 'Une erreur est survenue',
            message: this.$t(error.message),
            bgColor: 'red'
          })
        })
        .finally(() => {
          this.isModalCashbackUnlockFromPrepaidOpen = false
          this.isLoading = false
        })
    },

    setPrepaidCardNum () {
      return new Promise((resolve, reject) => {
        const clubist = {
          prePaidCardId: this.prepaidCardNumInput
        }

        this.isLoading = true
        this.$store.dispatch('editClubist', clubist)
          .then(resolve)
          .catch(reject)
          .finally(() => { this.isLoading = false })
      })
    },

    submitPrepaidCardNum () {
      this.$refs.prepaidCard.validate()
        .then(isValid => {
          if (isValid) {
            this.setPrepaidCardNum(this.prepaidCardNumInput)
              .then(() => {
                this.isModalSetPrepaidCardOpen = false
                this.toggleModalCashbackUnlockFromPrepaid()
              })
              .catch(error => {
                this.$store.commit('addAlert', {
                  title: 'Une erreur est survenue',
                  message: this.$t(error.message),
                  bgColor: 'red'
                })
              })
          } else {
            return false
          }
        })
        .catch(() => 1) // Silent
    },
    confirmPrepaidCardNum (num) {
      this.$eventHub.$emit('prepaid-card-confirmed', num)
      this.modalPrepaidCardConfirmNum = null
    },
    setModalPrepaidCardConfirm ({ num = null } = {}) {
      this.modalPrepaidCardConfirmNum = num
    },
    setModalEditPassword () {
      this.isModalEditPasswordOpen = !this.isModalEditPasswordOpen
    },
    toggleModalPrepaid () {
      this.isModalPrepaidOpen = !this.isModalPrepaidOpen
    },
    toggleModalPrepaidAndCashbackUnlock () {
      this.isModalPrepaidAndCashbackUnlockOpen = !this.isModalPrepaidAndCashbackUnlockOpen
    },
    toggleModalCashbackUnlockFromPrepaid () {
      this.isModalCashbackUnlockFromPrepaidOpen = !this.isModalCashbackUnlockFromPrepaidOpen
    },
    toggleModalCashbackUnlock () {
      this.isModalCashbackUnlockOpen = !this.isModalCashbackUnlockOpen
    },
    toggleModalAlreadyPrepaid () {
      this.isModalAlreadyPrepaidOpen = !this.isModalAlreadyPrepaidOpen
    },
    toggleModalLegals () {
      this.isModalLegalsOpen = !this.isModalLegalsOpen
    },
    toggleModalConditions () {
      this.isModalConditionsOpen = !this.isModalConditionsOpen
    },
    toggleModalRules () {
      this.isModalRulesOpen = !this.isModalRulesOpen
    },
    toggleModalWinAllowedInfo () {
      this.isModalWinAllowedInfoOpen = !this.isModalWinAllowedInfoOpen
    },
    toggleModalLatestWinners () {
      this.isModalLatestWinnersOpen = !this.isModalLatestWinnersOpen
    },
    toggleModalCard () {
      this.isModalCardOpen = !this.isModalCardOpen
    },
    toggleStatusAlert () {
      this.isModalStatusAlertOpen = !this.isModalStatusAlertOpen
    },
    toggleModalPhone () {
      this.isModalPhoneOpen = !this.isModalPhoneOpen
    },
    toggleModalNoPrepaidCard () {
      this.isModalNoPrepaidCardOpen = !this.isModalNoPrepaidCardOpen
    },
    toggleModalNoSecondaryPrepaidCard () {
      this.isModalNoSecondaryPrepaidCardOpen = !this.isModalNoSecondaryPrepaidCardOpen
    },
    toggleModalSetPrepaidCard () {
      this.isModalSetPrepaidCardOpen = !this.isModalSetPrepaidCardOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.large {
  font-size: 35px;
  font-weight: 800;
  display: block;
  margin-bottom: 1em;
}

.winner-container {
  padding: 16px;
  margin-bottom: 24px;
  border: 2px solid #164194;
  border-radius: 3.05px;
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.06);

  .winner-name {
    color: #164194;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: "Roboto";
  }

  .winner-date {
    color: #164194;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    font-family: "Roboto";
    text-transform: uppercase;
  }
}

.contact-block {
  width: 100%;
  max-width: 350px;
  margin-bottom: 15px !important;

  @include media('<phone') {
    padding: 8px 8px;
  }
}

.text-content {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}

.input-text {
  border: solid 1px $blue;
  border-radius: 3px;
  color: $blue;
}

.width-limit {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.link {
  display: inline-block;
  text-transform: none;
  font-weight: 500;
  &:hover {
    color: $red;
  }
}

.footer {
  font-size: 16px;
}
</style>
