
const stationToOption = station => ({
  code: station.id,
  name: station.name + ', ' + station.city + ', ' + station.street,
  city: station.name + ', ' + station.city,
  address: station.street
})

/**
 * Station helpers
 */
export default {
  data () {
    return {
      stationOption: this.$store.state.station && this.$store.state.station.name ? stationToOption(this.$store.state.station) : null,
      city: '',
      isLoading: false
    }
  },
  computed: {
    allStations () {
      return [...this.$store.state.stations, this.$store.state.station]
    },
    stationsOption () {
      return this.$store.state.stations
        .filter(station => station.city.toLowerCase() === this.city.toLowerCase()) // Keep only stations in same city
        .sort((a, b) => a.name.localeCompare(b.name) || a.street.localeCompare(b.street)) // Sort stations by alphabetical order: name, street
        .map(stationToOption)
    },
    citiesRest () {
      const indexOf = (word, letters) => word.toLowerCase().indexOf(letters.toLowerCase())
      return this.$store.state.stations.map(({ city }) => city) // Get all cities from stations
        .filter((city, index, array) => array.indexOf(city) === index) // Remove double
        .filter(city => indexOf(city, this.city) > -1) // Keep only with same letters
        .sort((a, b) => indexOf(a, this.city) - indexOf(b, this.city) || a.localeCompare(b)) // Sort cities by: shared word upstream, alphabetical order
        .filter((_, index) => index < 9) // Keep only 9 results
    }
  },
  created () {
    if (this.$store.state.stations.length < 1) {
      this.isLoading = true
      this.$store.dispatch('updateStations')
        .finally(() => { this.isLoading = false })
    }
  }
}
