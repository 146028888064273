import 'whatwg-fetch'
import 'date-input-polyfill'
import 'intersection-observer'
import 'minireset.css'
import '@/scss/element-ui/index.scss'
import '@/scss/app.scss'
import '@/assets/fonts/fonts.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from 'vue-select'
import VueAutosuggest from 'vue-autosuggest'
import Loading from 'element-ui/lib/loading'
import i18n from './locale/i18n'
import FooterSmall from '@/components/FooterSmall.vue'
import './vee-validate'
import './sentry'
import { version } from '../package.json'

Vue.config.productionTip = false

Vue.use(Loading)
Vue.component('v-select', vSelect)
Vue.component('FooterSmall', FooterSmall)
Vue.use(VueAutosuggest)

// Event hub for regroup global events
Vue.prototype.$eventHub = new Vue()

// Log current version
console.log('Client version', version)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
