<template>
  <div class="inscription page" v-loading="isLoading">
    <router-link class="back" :to="{ name: 'landing' }">
      <img src="../assets/svg/back.svg" alt="retour" width="14" height="23" />
    </router-link>
    <section class="section-header">
      <div class="container">
        <h2>Connexion</h2>
        <ValidationObserver ref="validationObserver" v-slot="{ valid }" @submit.prevent="submit()" class="form">
          <form>
            <div class="form-row">

              <!-- Club card -->
              <div class="form-group">
                <label for class="label">
                  N° de carte TotalEnergies Club*
                  <button
                    class="btn-info"
                    type="button"
                    @click="$eventHub.$emit('modal-card')"
                  >?</button>
                </label>
                <ValidationProvider ref="cardNum" rules="required|numeric|digits:19" v-slot="{ errors }" name="numéro de carte">
                  <div class="input-container">
                    <word-limit :word="cardNum" :max="19" />
                    <input type="text" class="input-text" maxlength="19" v-model="cardNum" />
                  </div>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- Password -->
              <div class="form-group">
                <label for class="label">Mot de passe*</label>
                <ValidationProvider ref="password" rules="required|min:8" v-slot="{ errors }" name="password">
                  <input type="password" class="input-text" v-model="password" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>

                <span class="btn btn-underline link" @click="resetPassword()">Mot de passe oublié</span>
              </div>
            </div>

            <div v-if="apiError" class="form-error">
              <span class="error">{{ apiError }}</span>
            </div>

            <VueRecaptcha
              v-if="recaptchaSiteKey !== 'disabled'"
              ref="recaptcha"
              :sitekey="recaptchaSiteKey"
              size="invisible"
              class="captcha"
              :loadRecaptchaScript="true"
              @verify="response => captchaResponse = response"
              @expired="resetRecaptcha"
              @render="resetRecaptcha"
              @error="resetRecaptcha"
            ></VueRecaptcha>
            <div class="form-validate">
              <button class="btn btn-submit" @click="submit()" :disabled="!valid || apiError || !isRecaptchaOk" :class="{disabled: !valid || apiError || !isRecaptchaOk}">Valider</button>
            </div>
            <router-link :to="{ name: 'register1' }" class="btn">Vous n'avez pas de compte ?</router-link>
          </form>
        </ValidationObserver>
      </div>
    </section>

    <FooterSmall class="end" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha'
import { config } from '@/config.js'
import WordLimit from '@/components/WordLimit.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha,
    WordLimit
  },
  data () {
    return {
      isLoading: false,
      password: '',
      cardNum: '',
      apiError: null,
      recaptchaSiteKey: config.recaptcha.sitekey,
      emailTotal: config.contact.email,
      captchaResponse: config.recaptcha.sitekey === 'disabled' ? 'disabled' : false
    }
  },
  computed: {
    isRecaptchaOk () {
      return !!this.captchaResponse || (config.recaptcha.sitekey === 'disabled')
    }
  },
  watch: {
    cardNum () { this.apiError = null },
    password () { this.apiError = null }
  },
  methods: {
    resetRecaptcha (info) {
      if (this.recaptchaSiteKey !== 'disabled') {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    },
    resetPassword () {
      // Reset password
      this.password = ''
      this.$refs.cardNum.reset()
      this.$refs.password.reset()

      // Check cardNum
      this.$refs.cardNum.validate()
        .then(({ valid, errors }) => {
          this.$refs.cardNum.reset()

          if (valid) {
            this.$store.dispatch('resetPasswordRequest', {
              cardNum: this.cardNum,
              recaptcha: this.captchaResponse
            })
              .then(() => {
                this.cardNum = ''

                // Wait next tick to bypass cardNum watcher
                this.$nextTick(() => {
                  this.apiError = this.$t('resetPassword.ask.success')
                })
              })
              .catch(error => {
                this.$refs.cardNum.reset()
                this.apiError = this.$t(error.message)
              })
          } else {
            throw new Error(errors.join('\n'))
          }
        })
        .catch(error => {
          this.apiError = error.message
        })
    },
    submit () {
      this.isLoading = true
      this.$store.dispatch('login', {
        cardNum: this.cardNum,
        password: this.password,
        recaptcha: this.captchaResponse
      })
        .then(() => {
          // Route can be auto-changed if clubist logged
          if (this.$route.name !== 'dashboard') {
            this.$router.push({ name: 'dashboard' })
          }

          this.$eventHub.$emit('refresh-clubist')
        })
        .catch(error => {
          this.resetRecaptcha('API load error')
          this.apiError = this.$t(error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.inscription {
  display: flex;
  flex-direction: column;
  .section-header {
    justify-self: center;
    align-self: center;
  }
}
.link {
  text-transform: none;
  color: #FFF;
  font-weight: 400;
}
.input-container {
  position: relative;
}
input::-ms-clear {
  // Fix IE11
  display: none;
}
.footer-small.end {
  margin-top: auto;
}
</style>
