<template>
  <div class="container">
    <h1>Bienvenue {{ $store.getters.firstName }}&nbsp;!</h1>
    <ul class="menu-list">
      <li>
        <router-link :to="{ name: 'dashboard' }" @click.native="close" class="link">Votre tableau de bord</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'settings-user' }" @click.native="close" class="link">Informations personnelles</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'settings-cards' }" @click.native="close" class="link">Vos cartes Club et TotalEnergies Card prépayée</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'settings-contact' }" @click.native="close" class="link">Préférences de contact</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'settings-support' }" @click.native="close" class="link">Service Client</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'faq' }" @click.native="close" class="link">FAQ</router-link>
      </li>
    </ul>
    <button @click="$store.dispatch('logout')" class="link logout">Déconnexion</button>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
div.container {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.logout {
  color: #FFF;
  font-size: 20px;
  font-weight: bold;
  border: 0;
  background: transparent;
}

.is-desktop.container  {
  min-width: 390px;
  padding: 70px 0;

  h1 {
    display: none;
  }

  .menu-list {
    margin-top: 0;
  }
}

.link {
  padding: 25px 55px 25px 35px;
  border: 0;
  background: none;
  font-size: 20px;
  color: #FFF;
  text-align: left;
}

h1 {
  padding: 25px 35px;
}

.menu-list {
  margin: 25px 0 50px;
}

li {
  font-size: 20px;
  text-decoration: none;
  position: relative;
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 35px;
    right: 35px;
    bottom: 0;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 11%,
      #ffffff 100%
    );
    opacity: 0.3;
  }

  .link:after {
    content: '';
    display: block;
    width: 30px;
    position: absolute;
    top: 0;
    right: 35px;
    bottom: 0;
    background-image: url('~@/assets/svg/next.svg');
    background-repeat: no-repeat;
    background-position: right center;
  }

  a, button {
    display: block;
    text-decoration: none;
  }
}
</style>
