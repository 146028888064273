<template>
  <component :href="href" @click="openModal()" v-bind:is="isMobile ? 'a' : 'button'" :class="{ btn: !disableStyle }">
    <slot>
      Contacter le Service Client
    </slot>
  </component>
</template>

<script>
import { config } from '@/config.js'
import { IS_MOBILE } from '@/pure/device.js'

export default {
  props: {
    disableStyle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMobile: IS_MOBILE,
      href: IS_MOBILE ? 'tel:' + config.contact.phone.split(' ').join('') : false
    }
  },
  methods: {
    openModal () {
      if (!this.isMobile) {
        this.$eventHub.$emit('modal-phone')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
