<template>
  <transition :name="transitionName">
    <slot />
  </transition>
</template>

<script>
export default {
  // then, in the parent component,
  // watch the `$route` to determine the transition to use
  data () {
    return {
      transitionName: ''
    }
  },
  watch: {
    '$route' (to, from) {
      this.transitionName = to.meta.transitionName
      if (!this.transitionName) {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
    }
  }

}
</script>

<style lang="scss">
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active,
.fade-enter-active,
.fade-leave-active {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  transition-duration: 0.8s, 0.8s;
  transition: 0.8s transform $easeOutCirc, 0.8s opacity $easeOutCirc;
  overflow: hidden;
}
.slide-left-enter,
.slide-right-leave-active {
  transform: translate(100%, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  transform: translate(-100%, 0);
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
