<template>
  <div class="booster-explanation">
    <div class="fusee-container">
      <img src="../assets/svg/fusee.svg" width="51" height="52" alt="" />
    </div>

    <h3 v-html="$t('boosterExplanation.content')"></h3>
  </div>
</template>

<script>
export default {
  name: 'BoosterExplanation'
}
</script>

<style lang="scss" scoped>
.booster-explanation {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 32px 24px;
  background-color: $red;
  background-image: url(~@/assets/svg/global-bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;

  .fusee-container {
    width: 125px;
    display: inline-block;

    img {
      display: block;
      max-height: 100%;
      margin: 0 auto;
    }
  }

  h3 {
    display: inline-block;
    margin-left: 8px;
    line-height: 1.5;
    text-align: center;
    color: #fff;
  }
}
</style>
