/**
 * Convert a date object to a standart date string format.
 * ex: "2020-08-20"
 *
 * @param {Date} date   Date object to convert
 * @return {String}
 */
export const dateToStdDate = (date) => {
  return date.toLocaleDateString('fr-FR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
    .split('/')
    .reverse()
    .join('-')
}

/**
 * Convert an standart date string to a french date string.
 * ex: "2020-08-20" -> "20/08/2020"
 *
 * @param {String} stdDateStr   Standart date string to convert
 * @return {String}
 */
export const stdDateToFrDate = (stdDateStr) => {
  const date = new Date(stdDateStr)
  if (isNaN(date.getTime())) {
    return ''
  }
  return date.toLocaleDateString('fr-FR')
}

/**
 * Convert an standart date string to a small french date string.
 * ex: "2019-08-20" -> "20/08/19"
 *
 * @param {String} stdDateStr   Standart date string to convert
 * @return {String}
 */
export const stdDateToSmallFrDate = (stdDateStr) => {
  const date = new Date(stdDateStr)
  if (isNaN(date.getTime())) {
    return ''
  }
  return date.toLocaleDateString('fr-FR', { year: '2-digit', month: 'numeric', day: 'numeric' })
}

/**
 * Convert a french date string to a standart date string.
 * ex: "20/08/2020" -> "2020-08-20"
 *
 * @param {String} frDateStr   French date string to convert
 * @return {String}
 */
export const frDateToStdDate = (frDateStr) => {
  const dateEnArr = (frDateStr || '').split('/')
  if (dateEnArr.length !== 3) {
    return ''
  } else {
    const date = new Date(dateEnArr[2] + '/' + dateEnArr[1] + '/' + dateEnArr[0])
    return dateToStdDate(date)
  }
}

/**
 * Get the first day of the current lottery
 *
 * @param {Date} now
 * @return {Date}
 */
export const getStartCurrentLotteryDate = (now) => {
  const lotteryDate = getLotteryDate(now)
  const monday = new Date(lotteryDate.getTime() - 1000 * 60 * 60 * 24 * 7)
  return monday
}

/**
 * Get the last day for the current lottery
 *
 * @param {Date} now
 * @return {Date}
 */
export const getEndCurrentLotteryDate = (now) => {
  return getLotteryDate(now)
}

/**
 * Get the next lottery date (Monday at 11:00)
 *
 * @param {Date} now
 * @return {Date}
 */
export const getLotteryDate = (now) => {
  const lotteryHour = 11
  let daysToAdd = 0
  if (now.getDay() === 1) { // Today
    if (now.getHours() < lotteryHour) {
      daysToAdd = 0
    } else { // +7 days
      daysToAdd = 7
    }
  } else {
    daysToAdd = { 0: 1, 2: 6, 3: 5, 4: 4, 5: 3, 6: 2 }[now.getDay()]
  }

  const date = new Date(now.getTime() + 1000 * 60 * 60 * 24 * daysToAdd)
  date.setHours(lotteryHour, 0, 0, 0)

  return date
}
