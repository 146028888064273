<template>
  <div class="landing-page page">
    <section class="section-header">
      <div class="container">
        <h1 v-html="$t('landing.title')"></h1>

        <p class="tagline" v-html="$t('landing.create.resume')"></p>
        <router-link :to="{ name: 'register1' }" class="btn" v-html="$t('landing.signup.button')"></router-link>

        <p class="tagline" v-html="$t('landing.signup.resume')"></p>
        <router-link :to="{ name: 'register1' }" class="btn" v-html="$t('landing.create.button')"></router-link>

        <p class="tagline" v-html="$t('landing.signin.resume')"></p>
        <router-link :to="{ name:'login' }" class="btn" v-html="$t('landing.signin.button')"></router-link>
      </div>
    </section>

    <FooterLarge />
  </div>
</template>

<script>
import FooterLarge from '@/components/FooterLarge.vue'

export default {
  name: 'Landing',

  components: {
    FooterLarge
  }
}
</script>

<style lang="scss" scoped>
.section-header {
  justify-self: center;
  align-self: flex-start;

  .container {
    @include media(">desktop") {
      padding: 50px 0 25px 0;
    }
  }
}
h1 {
  margin-bottom: 0.25em;
}
.tagline {
  margin: 30px 0 20px;
}
@include media(">desktop") {
  .landing-page > .section-header {
    // fix IE11
    // - disabled because it seems to work without it and it was causing another layout issue
    //   (https://gitlab.numberly.in/team-uep/total-maroc/b2c/global/-/issues/158)
    // width: 100vw;
  }
}
@media screen and (min-width: 2000px) {
  .page.landing-page {
    #app & {
      padding: 0;
    }
  }
}
</style>
