<template>
  <footer class="footer-small">
    <FooterLinks />
  </footer>
</template>

<script>
import FooterLinks from '@/components/FooterLinks.vue'

export default {
  name: 'FooterSmall',

  components: {
    FooterLinks
  }
}
</script>

<style lang="scss" scoped>
.footer-small {
  position: relative;
  padding: 13px 32px 15px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  margin: 0;
}
</style>
