import { extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'

for (let rule in rules) {
  extend(rule, rules[rule])
}

extend('date', {
  validate: (value) => {
    let splitValue, date
    if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
      splitValue = value.split('/')
      if (splitValue[1] - 1 > 11) {
        return false
      } else if (splitValue[0] > 31) {
        return false
      } else {
        date = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
        return date instanceof Date && !isNaN(date)
      }
    } else {
      splitValue = value.split('-')
      if (splitValue[1] - 1 > 11) {
        return false
      } else if (splitValue[2] > 31) {
        return false
      } else {
        date = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
        return date instanceof Date && !isNaN(date)
      }
    }
  },
  message: (field) => `La date n'est pas valide`
})

extend('ageMin', {
  params: ['ageMin'],
  validate: (value, { ageMin }) => {
    var userDate, splitValue
    if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
      splitValue = value.split('/')
      userDate = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
    } else {
      splitValue = value.split('-')
      userDate = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
    }
    var newYear = userDate.getFullYear() + parseInt(ageMin)
    var tempDate = new Date(newYear, userDate.getMonth(), userDate.getDate())
    return tempDate <= new Date()
  },
  message: 'Il faut avoir au moins {ageMin} ans pour participer'
})

extend('ageMax', {
  params: ['ageMax'],
  validate: (value, { ageMax }) => {
    var userDate, splitValue
    if (value.match(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)) {
      splitValue = value.split('/')
      userDate = new Date(splitValue[2], parseInt(splitValue[1] - 1), splitValue[0])
    } else {
      splitValue = value.split('-')
      userDate = new Date(splitValue[0], parseInt(splitValue[1] - 1), splitValue[2])
    }
    var date = new Date()
    return userDate.getFullYear() >= date.getFullYear() - parseInt(ageMax)
  },
  message: `Date invalide.`
})

extend('noArabicChars', {
  validate: value => /^[^\u0621-\u064A]+$/.test(value),
  message: 'Les caractères arabes ne sont pas supportés'
})

extend('marocPhoneCode', {
  validate: (value) => value >= 212000000000 && value <= 212999999999,
  message: `le n° de téléphone doit commencer par 212`
})

extend('marocPhoneStart', {
  validate: (value) => String(value).indexOf('2120') !== 0,
  message: `Le chiffre 0 n'est pas accepté après l'indicatif`
})

extend('checked', {
  validate: (value) => value === true,
  message: `Ce champs est obligatoire`
})

localize('fr', fr)
