
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from './fr'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'fr',
  messages: {
    fr
  },
  silentTranslationWarn: true,
  missing: (locale, key, vm) => {
    // Add fallback message for unreferenced graphql errors
    if (key.includes('gql.')) {
      return vm.$t('api.serverError')
    }
    return key
  }
})

export default i18n
