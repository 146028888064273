<template>
  <div class="menu" :class="{open: menuOpened}">
    <MenuContent class="container" @close="closeMenu" />
  </div>
</template>

<script>
import MenuContent from '@/components/MenuContent.vue'

export default {
  props: ['menuOpened'],
  components: {
    MenuContent
  },
  methods: {
    closeMenu () {
      this.$emit('closeMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
.menu {
  // opacity: 0;
  transform: translateX(100%);
  transition: 400ms $easeOutCirc;
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $red;
  background-image: url(~@/assets/svg/global-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 200%;
  pointer-events: none;
  color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-scrolling: touch;
  display: flex;

  @include media('>tablet') {
    left: auto;
    width: 320px;
  }
  &.open {
    opacity: 1;
    transform: translateX(0);
    pointer-events: initial;
  }

  @include media('>large') {
    display: none;
  }
}
</style>
