<template>
  <vue-autosuggest
    class="input-text"
    :suggestions="[{ data: suggest }]"
    :input-props="{ id: 'autosuggest__input', placeholder }"
    :value="value"
    :sectionConfigs="sectionConfigs"
    @input="value => $emit('change', value)"
    @selected="value => $emit('change', value.item)"
    @click="() => 1"
    ref="autosuggest"
  >
    <template slot-scope="{suggestion}">
      <span class="my-suggestion-item">{{suggestion.item}}</span>
    </template>
  </vue-autosuggest>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    suggest: {
      type: Array,
      default () { return [] }
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sectionConfigs: { // Keep this to avoid error throwing
        default: {
          onSelected: (data) => {
            if (data && data.item) {
              this.$emit('change', data.item)
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

#autosuggest.input-text {
  padding: 0;
  position: relative;
  border: 0;

  ::v-deep {
    input {
      display: block;
      border: solid 1px $blue;
      border-radius: 3px;
      color: $blue;
      font-size: 14px;
      padding: 10px;
      width: 100%;
      &::placeholder {
        color: $blue;
        opacity: 0.5;
      }
    }

    .autosuggest__results-container {
      position: absolute;
      width: 100%;
      z-index: 3;
    }

    .autosuggest__results {
      position: relative;
      background: #FFF;
      &:before {
        content: "";
        display: block;
        top: -4px;
        left: 25px;
        width: 6px;
        height: 6px;
        position: absolute;
        border-left: solid 1px $blue;
        border-top: solid 1px $blue;
        background: #FFF;
        transform: rotate(45deg);
      }

      border: solid 1px $blue;
      border-radius: 3px;
      margin: 8px 0 0 0;
      font-weight: bold;

      .autosuggest__results-item {
        text-align: left;
        cursor: pointer;

        &:first-child {
          padding-top: 11px;
        }

        &:last-child {
          padding-bottom: 11px;
        }

        &>* {
          display: block;
          padding: 3px 18px;
        }

        &:hover {
          &>* {
            background: rgba($blue, 0.1);
          }
        }
      }

      .my-suggestion-item {
        color: $blue;
      }
    }
  }

  &.is-red {
    ::v-deep {
      input {
        color: white;
        background: transparent;
        border-color: white;
        &::placeholder {
          color: white;
        }
      }

      .autosuggest__results {
        background: $red;
        &:before {
          border-left: solid 1px white;
          border-top: solid 1px white;
          background: $red;
        }

        border-color: white;

        .autosuggest__results-item {
          &:hover {
            &>* {
              background: rgba(white, 0.1);
            }
          }
        }

        .my-suggestion-item {
          color: white;
        }
      }
    }
  }
}
</style>
