<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" xml:space="preserve">
    <g :fill="color" stroke-width="0">
      <path class="color" d="M14.303507,18.627853 L4.9896,18.627853 C4.80599831,18.627853 4.65697299,18.7768783 4.65697299,18.96048 L4.65697299,19.625773 C4.65697299,19.8093747 4.80599831,19.9584 4.9896,19.9584 C5.17320169,19.9584 5.32222701,19.8093747 5.32222701,19.625773 L5.32222701,19.293146 L14.303507,19.293146 C14.4871087,19.293146 14.636134,19.1441207 14.636134,18.960519 C14.636134,18.7769173 14.4871477,18.627853 14.303507,18.627853 Z"></path>
      <path class="color" d="M7.31806701,8.31598701 C7.31806701,8.49958869 7.46709233,8.64861401 7.65069401,8.64861401 L8.98128,8.64861401 C9.16488169,8.64861401 9.31390701,8.49958869 9.31390701,8.31598701 L9.31390701,6.32014701 C9.31390701,6.13654532 9.16488169,5.98752 8.98128,5.98752 L7.65073299,5.98752 C7.46713131,5.98752 7.31810599,6.13654532 7.31810599,6.32014701 L7.31810599,8.31598701 L7.31806701,8.31598701 Z M7.98336,6.65281299 L8.64865299,6.65281299 L8.64865299,7.98336 L7.98336,7.98336 L7.98336,6.65281299 Z"></path>
      <path class="color" d="M4.32430701,3.99168 L0.332627006,3.99168 C0.149025319,3.99168 0,4.14070532 0,4.32430701 L0,10.97712 C0,11.1607217 0.149025319,11.309747 0.332627006,11.309747 C0.516228694,11.309747 0.665254013,11.1607217 0.665254013,10.97712 L0.665254013,4.65697299 L4.32430701,4.65697299 C4.50790869,4.65697299 4.65693401,4.50794767 4.65693401,4.32434599 C4.65693401,4.1407443 4.50794768,3.99168 4.32430701,3.99168 Z"></path>
      <path class="color" d="M15.96672,16.299347 C15.96672,16.4829487 16.1157453,16.631974 16.299347,16.631974 L17.629894,16.631974 C17.8134957,16.631974 17.962521,16.4829487 17.962521,16.299347 L17.962521,14.303507 C17.962521,14.1199053 17.8134957,13.97088 17.629894,13.97088 L16.299347,13.97088 C16.1157453,13.97088 15.96672,14.1199053 15.96672,14.303507 L15.96672,16.299347 Z M16.632013,14.636173 L17.297306,14.636173 L17.297306,15.96672 L16.632013,15.96672 L16.632013,14.636173 Z"></path>
      <path class="color" d="M1.66321299,11.97504 C0.0189448875,11.97504 0,16.2561168 0,16.299347 C0,17.1026726 0.572478637,17.7742806 1.33054701,17.9289582 L1.33054701,19.625773 C1.33054701,19.8093747 1.47957232,19.9584 1.66317401,19.9584 C1.8467757,19.9584 1.99580102,19.8093747 1.99580102,19.625773 L1.99580102,17.9289582 C2.75386939,17.7742806 3.32634808,17.1026726 3.32634808,16.299347 C3.32638701,16.2561168 3.30744212,11.97504 1.66321299,11.97504 Z M1.66321299,17.297267 C1.11303163,17.297267 0.665292994,16.8495284 0.665292994,16.299347 C0.665292994,14.6421371 1.11038091,12.640294 1.66321299,12.640294 C2.21604508,12.640294 2.66113299,14.6421371 2.66113299,16.299347 C2.66113299,16.8495284 2.21339436,17.297267 1.66321299,17.297267 Z"></path>
      <path class="color" d="M3.65905299,9.9792 L2.32846701,9.9792 C2.14486532,9.9792 1.99584,10.1282253 1.99584,10.311827 L1.99584,10.97712 C1.99584,11.1607217 2.14486532,11.309747 2.32846701,11.309747 C2.51206869,11.309747 2.66109401,11.1607217 2.66109401,10.97712 L2.66109401,10.644493 L3.32638701,10.644493 L3.32638701,12.307706 C3.32638701,12.4913077 3.47541232,12.640333 3.65901401,12.640333 C3.8426157,12.640333 3.99164103,12.4913077 3.99164103,12.307706 L3.99164103,10.311866 C3.99168,10.1282253 3.84265468,9.9792 3.65905299,9.9792 Z"></path>
      <path class="color" d="M16.299347,8.64865299 L17.629894,8.64865299 C17.8134957,8.64865299 17.962521,8.49962767 17.962521,8.31602599 L17.962521,6.32018599 C17.962521,6.1365843 17.8134957,5.98755898 17.629894,5.98755898 L16.299347,5.98755898 C16.1157453,5.98755898 15.96672,6.1365843 15.96672,6.32018599 L15.96672,8.31602599 C15.96672,8.49962768 16.1157453,8.64865299 16.299347,8.64865299 Z M16.632013,6.65281299 L17.297306,6.65281299 L17.297306,7.98336 L16.632013,7.98336 L16.632013,6.65281299 Z"></path>
      <path class="color" d="M3.65905299,5.98752 L2.32846701,5.98752 C2.14486532,5.98752 1.99584,6.13654532 1.99584,6.32014701 L1.99584,8.31598701 C1.99584,8.49958869 2.14486532,8.64861401 2.32846701,8.64861401 L3.65901401,8.64861401 C3.8426157,8.64861401 3.99164103,8.49958869 3.99164103,8.31598701 L3.99164103,6.32014701 C3.99168,6.13654532 3.84265468,5.98752 3.65905299,5.98752 Z M3.32638701,7.98336 L2.66113299,7.98336 L2.66113299,6.65281299 L3.32642599,6.65281299 L3.32642599,7.98336 L3.32638701,7.98336 Z"></path>
      <path class="color" d="M10.644493,8.31598701 C10.644493,8.49958869 10.7935183,8.64861401 10.97712,8.64861401 L12.307667,8.64861401 C12.4912687,8.64861401 12.640294,8.49958869 12.640294,8.31598701 L12.640294,6.32014701 C12.640294,6.13654532 12.4912687,5.98752 12.307667,5.98752 L10.97712,5.98752 C10.7935183,5.98752 10.644493,6.13654532 10.644493,6.32014701 L10.644493,8.31598701 Z M11.309747,6.65281299 L11.97504,6.65281299 L11.97504,7.98336 L11.309747,7.98336 L11.309747,6.65281299 Z"></path>
      <path class="color" d="M19.625773,3.99168 L15.634093,3.99168 C15.4504913,3.99168 15.301466,4.14070532 15.301466,4.32430701 C15.301466,4.50790869 15.4504913,4.65693401 15.634093,4.65693401 L19.293146,4.65693401 L19.293146,18.0237606 C19.1887152,17.9864945 19.0779305,17.96256 18.960519,17.96256 C18.9029827,17.96256 18.8457582,17.9675496 18.7895472,17.9775288 C18.5600256,17.5640547 18.1232797,17.297267 17.629972,17.297267 C17.1366643,17.297267 16.6999183,17.5640547 16.4703967,17.9775288 C16.4141858,17.9675496 16.3569613,17.96256 16.299425,17.96256 C15.7492436,17.96256 15.301466,18.4102986 15.301466,18.96048 C15.301466,19.5106614 15.7492436,19.9584 16.299425,19.9584 L18.96048,19.9584 C19.5106614,19.9584 19.9584,19.5106614 19.9584,18.96048 L19.9584,4.32430701 C19.9584,4.14070532 19.8093747,3.99168 19.625773,3.99168 Z M18.96048,19.293107 L16.299347,19.293107 C16.1160572,19.293107 15.96672,19.1437698 15.96672,18.96048 C15.96672,18.7771902 16.1160572,18.627853 16.299347,18.627853 C16.3631983,18.627853 16.4237362,18.6461352 16.4796353,18.6820759 C16.5707735,18.7406258 16.6845597,18.7512676 16.7850144,18.7106882 C16.8854691,18.6701087 16.9596504,18.5829466 16.9839357,18.4775023 C17.054141,18.1744621 17.3199151,17.96256 17.629933,17.96256 C17.9399509,17.96256 18.205725,18.1744621 18.2759303,18.4775023 C18.3002156,18.5829466 18.3743969,18.6701087 18.4748516,18.7106882 C18.5749944,18.7512676 18.6890536,18.7406257 18.7802307,18.6820759 C18.9967715,18.5423671 19.293146,18.7133389 19.293146,18.96048 C19.293107,19.1437698 19.1437698,19.293107 18.96048,19.293107 Z"></path>
      <path class="color" d="M10.97712,4.65697299 L12.307667,4.65697299 C12.4912687,4.65697299 12.640294,4.50794768 12.640294,4.32434599 L12.640294,2.32850599 C12.640294,2.1449043 12.4912687,1.99587898 12.307667,1.99587898 L10.97712,1.99587898 C10.7935183,1.99587898 10.644493,2.1449043 10.644493,2.32850599 L10.644493,4.32434599 C10.644493,4.50794768 10.7935183,4.65697299 10.97712,4.65697299 Z M11.309747,2.66113299 L11.97504,2.66113299 L11.97504,3.99168 L11.309747,3.99168 L11.309747,2.66113299 Z"></path>
      <path class="color" d="M10.644493,12.307667 C10.644493,12.4912687 10.7935183,12.640294 10.97712,12.640294 L12.307667,12.640294 C12.4912687,12.640294 12.640294,12.4912687 12.640294,12.307667 L12.640294,10.311827 C12.640294,10.1282253 12.4912687,9.9792 12.307667,9.9792 L10.97712,9.9792 C10.7935183,9.9792 10.644493,10.1282253 10.644493,10.311827 L10.644493,12.307667 Z M11.309747,10.644493 L11.97504,10.644493 L11.97504,11.97504 L11.309747,11.97504 L11.309747,10.644493 Z"></path>
      <path class="color" d="M7.65073299,4.65697299 L8.98128,4.65697299 C9.16488169,4.65697299 9.313907,4.50794768 9.313907,4.32434599 L9.313907,2.32850599 C9.313907,2.1449043 9.16488169,1.99587898 8.98128,1.99587898 L7.65073299,1.99587898 C7.4671313,1.99587898 7.31810598,2.1449043 7.31810598,2.32850599 L7.31810598,4.32434599 C7.318067,4.50794768 7.46709232,4.65697299 7.65073299,4.65697299 Z M7.98336,2.66113299 L8.64865299,2.66113299 L8.64865299,3.99168 L7.98336,3.99168 L7.98336,2.66113299 Z"></path>
      <path class="color" d="M9.74301261,16.0631986 C9.68313741,16.1263872 9.64653401,16.2128866 9.64653401,16.299386 C9.64653401,16.3858854 9.68313741,16.4723458 9.74301261,16.5355734 C9.80620121,16.5954486 9.89270061,16.632052 9.9792,16.632052 C10.0656994,16.632052 10.1521598,16.5954486 10.2153874,16.5355734 C10.2752626,16.4723848 10.311866,16.3858854 10.311866,16.299386 C10.311866,16.2128866 10.2752626,16.1264262 10.2153874,16.0631986 C10.0889712,15.9400958 9.8694288,15.9400958 9.74301261,16.0631986 Z"></path>
      <path class="color" d="M7.31806701,12.307667 C7.31806701,12.4912687 7.46709233,12.640294 7.65069401,12.640294 L8.98128,12.640294 C9.16488169,12.640294 9.31390701,12.4912687 9.31390701,12.307667 L9.31390701,10.311827 C9.31390701,10.1282253 9.16488169,9.9792 8.98128,9.9792 L7.65073299,9.9792 C7.46713131,9.9792 7.31810599,10.1282253 7.31810599,10.311827 L7.31810599,12.307667 L7.31806701,12.307667 Z M7.98336,10.644493 L8.64865299,10.644493 L8.64865299,11.97504 L7.98336,11.97504 L7.98336,10.644493 Z"></path>
      <path class="color" d="M6.65281299,14.303507 C6.65281299,14.4871087 6.80183831,14.636134 6.98544,14.636134 L7.98336,14.636134 L7.98336,17.629894 C7.98336,17.8134957 8.13238532,17.962521 8.31598701,17.962521 C8.49958869,17.962521 8.64861401,17.8134957 8.64861401,17.629894 L8.64861401,14.636134 L11.309747,14.636134 L11.309747,17.629894 C11.309747,17.8134957 11.4587723,17.962521 11.642374,17.962521 C11.8259757,17.962521 11.975001,17.8134957 11.975001,17.629894 L11.975001,14.636134 L12.972921,14.636134 C13.1565227,14.636134 13.305548,14.4871087 13.305548,14.303507 C13.305548,14.1199053 13.1565227,13.97088 12.972921,13.97088 L6.98544,13.97088 C6.80183831,13.97088 6.65281299,14.1199053 6.65281299,14.303507 Z"></path>
      <path class="color" d="M15.96672,12.307667 C15.96672,12.4912687 16.1157453,12.640294 16.299347,12.640294 L17.629894,12.640294 C17.8134957,12.640294 17.962521,12.4912687 17.962521,12.307667 L17.962521,10.311827 C17.962521,10.1282253 17.8134957,9.9792 17.629894,9.9792 L16.299347,9.9792 C16.1157453,9.9792 15.96672,10.1282253 15.96672,10.311827 L15.96672,12.307667 Z M16.632013,10.644493 L17.297306,10.644493 L17.297306,11.97504 L16.632013,11.97504 L16.632013,10.644493 Z"></path>
      <path class="color" d="M14.303507,0 L5.65489299,0 C5.47129131,0 5.32226599,0.149025319 5.32226599,0.332627006 L5.32226599,17.629894 C5.32226599,17.8134957 5.47129131,17.962521 5.65489299,17.962521 C5.83849468,17.962521 5.98752,17.8134957 5.98752,17.629894 L5.98752,0.665254013 L13.97088,0.665254013 L13.97088,17.629894 C13.97088,17.8134957 14.1199053,17.962521 14.303507,17.962521 C14.4871087,17.962521 14.636134,17.8134957 14.636134,17.629894 L14.636134,0.332627006 C14.636134,0.149025319 14.4871477,0 14.303507,0 Z"></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: '20'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
