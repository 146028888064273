import Vue from 'vue'
import Router from 'vue-router'
import Landing from '@/views/Landing.vue'
import Login from '@/views/Login.vue'
import Error404 from '@/views/Error404.vue'
import Dashboard from '@/views/Dashboard.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
      meta: {
        transitionName: 'slide-right'
      }
    },
    {
      path: '/connexion',
      name: 'login',
      component: Login
    },
    // Reset password
    {
      path: '/forgot_password/:hash',
      name: 'resetPassword',
      component: () => import(/* webpackChunkName: "settings" */ './views/ResetPassword.vue')
    },
    {
      path: '/inscription',
      name: 'register1',
      component: () => import(/* webpackChunkName: "register" */ './views/Register1.vue')
    },
    {
      path: '/inscription/informations',
      name: 'register2',
      component: () => import(/* webpackChunkName: "register" */ './views/Register2.vue')
    },
    {
      path: '/inscription/informations/mot-de-passe',
      name: 'register3',
      component: () => import(/* webpackChunkName: "register" */ './views/Register3.vue')
    },
    {
      path: '/landing-faq',
      name: 'landing-faq',
      component: () => import(/* webpackChunkName: "settings" */ './views/LandingFAQ.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "settings" */ './views/FAQ.vue'),
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '/profil',
      name: 'settings-user',
      component: () => import(/* webpackChunkName: "settings" */ './views/SettingsUser.vue'),
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '/profil/cartes',
      name: 'settings-cards',
      component: () => import(/* webpackChunkName: "settings" */ './views/SettingsCards.vue'),
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '/profil/contact',
      name: 'settings-contact',
      component: () => import(/* webpackChunkName: "settings" */ './views/SettingsContact.vue'),
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '/profil/reclamation',
      name: 'settings-support',
      component: () => import(/* webpackChunkName: "settings" */ './views/SettingsSupport.vue'),
      meta: { transitionName: 'fade', requiresAuth: true }
    },
    {
      path: '*',
      name: 'error404',
      component: Error404,
      meta: {
        transitionName: 'slide-right'
      }
    }
  ]
})

export default router
