import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import ApiRequest from '@/pure/api-requests'

import {
  decodeStationData,
  decodeClubistData,
  decodeClubistDataFlexible,
  encodeClubistData,
  encodeRegister1,
  encodeRegister2,
  encodeRegister3,
  decodeUserData
} from '@/pure/api-parser'

import {
  login as requestLogin,
  register1 as requestRegister1,
  register2 as requestRegister2,
  register3 as requestRegister3,
  clubist as requestClubist,
  stations as requestStations,
  clubistEdit as requestEditClubist,
  logout as requestLogout,
  prePaidCardClaim as requestClaimPrePaidCard,
  cashbackClaimUnlock as requestClaimUnlockCashback,
  info as requestInfos,
  user as requestUser,
  passwordReset as requestPasswordReset,
  passwordForgot as requestPasswordForgot
} from '../../graphql-sdl/requests-clubist.graphql'

import lotteryModule from './modules/lottery'

const updateClubist = (commit, clubistData) => {
  try {
    const clubist = decodeClubistData(clubistData)
    commit('clubist', { clubist })
    return clubist
  } catch (error) {
    commit('logout')
    throw error
  }
}

const apiRequest = new ApiRequest()

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: [
      'clubistID',
      'subscribeData',
      'lastDailyAlert'
    ]
  })],

  modules: {
    lottery: lotteryModule
  },

  state: {
    subscribeData: { },
    clubistID: null,
    clubist: null, // Object
    stations: [],
    alerts: [],
    displayAlert: true,
    times: {},
    lastDailyAlert: 0
  },

  getters: {
    isLogged: state => !!state.clubist,
    firstName: state => state.clubist ? state.clubist.firstName || '' : '',
    lastName: state => state.clubist ? state.clubist.lastName || '' : '',
    email: state => state.clubist ? state.clubist.email || '' : '',
    civility: state => state.clubist ? state.clubist.civility || '' : '',
    birthDate: state => state.clubist ? state.clubist.birthDate || '' : '',
    city: state => state.clubist ? state.clubist.city || '' : '',
    isCashing: state => state.clubist && state.clubist.cashbackUnlockClaim,
    cashingAmount: state => (state.clubist && state.clubist.cashbackUnlockClaim) ? state.clubist.cashbackUnlockClaim.cashback || 0 : 0,
    isCar: state => state.clubist ? state.clubist.isCar === true : true,
    isTaxi: state => state.clubist ? state.clubist.isTaxi === true : false,
    isTruck: state => state.clubist ? state.clubist.isTruck === true : false,
    isWinAllowed: state => state.clubist ? state.clubist.isWinAllowed === true : false,
    isWinAlert: state => state.clubist ? state.clubist.winAlert === true : false,
    isDrawAlert: state => state.clubist ? state.clubist.drawAlert === true : false,
    isOptinSms: state => state.clubist ? state.clubist.isOptinSms === true : null,
    isOptinEmail: state => state.clubist ? state.clubist.isOptinEmail === true : null,
    statusID: state => state.clubist ? state.clubist.statusID || 0 : 0,
    status: state => ['Normal', 'Averti', 'Bloqué'][state.clubist ? state.clubist.statusID || 0 : 0],
    isBanned: state => state.clubist && state.clubist.statusID === 2,
    isWarned: state => state.clubist && state.clubist.statusID === 1,
    cashback: state => state.clubist ? state.clubist.cashback || 0 : 0,
    dirhamsExtracting: state => state.clubist !== null && state.clubist.dirhamsExtracting,
    dirhamsToUnlock: state => Math.min(Math.floor((state.clubist ? state.clubist.cashback || 0 : 0) / 50) * 50),
    cardNum: state => state.clubist ? state.clubist.cardNum || null : null,
    prepaidCardNum: state => state.clubist ? state.clubist.prepaidCardNum || null : null,
    prePaidCardClaimed: state => state.clubist ? state.clubist.prePaidCardClaimed || null : null,
    isPrepaidCardClaimOnDelivery: state => state.clubist ? state.clubist.isPrepaidCardClaimOnDelivery || false : false,
    hasAlreadyClaimFirstPrepaidCard: state => state.clubist ? state.clubist.hasAlreadyClaimFirstPrepaidCard || false : false,
    hasAlreadyClaimPrepaidCard: state => state.clubist ? state.clubist.hasAlreadyClaimPrepaidCard || false : false,
    phone: state => state.clubist ? state.clubist.phone || null : null,
    language: state => state.clubist ? state.clubist.language || null : null,
    alert: state => state.alerts.length > 0 && state.displayAlert ? state.alerts[0] : null,

    /**
     * Delay between the server and the client: to avoid false time from bad OS client configuration
     */
    clientDelay: state => state.times.server && state.times.client ? (state.times.server - state.times.client) : 0
  },

  mutations: {
    times (state, { server, client }) {
      state.times = {
        server,
        client
      }
    },
    logout (state) {
      state.clubistID = null
      state.clubist = null
      state.stations = []
      state.alerts = []
    },
    clubist (state, { clubist }) {
      if (clubist && clubist.id) {
        state.clubistID = clubist.id
      } else if (!clubist) {
        state.clubistID = null
      }
      state.clubist = clubist
      return clubist
    },
    subscribeData (state, { clubist }) {
      state.subscribeData = clubist
    },
    addAlert (state, { title, message, additional = null, bgImg = null, bgColor = null, type = null, onClosed = null }) {
      const alert = {
        title,
        message,
        additional,
        bgImg,
        bgColor,
        type,
        onClosed
      }

      // Unauthorize same alert
      const double = state.alerts.find(current => JSON.stringify(current) === JSON.stringify(alert))
      if (!double) {
        state.alerts.push(alert)
      }
    },
    removeAlert (state) {
      if (state.alerts.length > 0) {
        state.displayAlert = false

        const alert = state.alerts.shift()
        if (alert.onClosed) {
          alert.onClosed()
        }

        // Add time before show next alert
        setTimeout(() => {
          state.displayAlert = true
        }, 500)
      }
    },
    stations (state, stations) {
      state.stations = stations
    },
    lastDailyAlert (state, lastDailyAlert) {
      state.lastDailyAlert = lastDailyAlert
    }
  },

  actions: {
    updateServerTime ({ commit }) {
      return apiRequest.query(requestInfos)
        .then(data => {
          try {
            console.log('Server version:', data.info.version)

            // Get Morocco timezone offset
            const moroccoTimezoneOffsetStr = data.info.moroccoDateTime.substring(26)
            const moroccoTimezoneOffset = Number(moroccoTimezoneOffsetStr.split(':')[0]) + Number(moroccoTimezoneOffsetStr.split(':')[1]) / 60

            const server = new Date(data.info.currentDateTime).getTime() + moroccoTimezoneOffset * 60 * 60 * 1000
            const client = Date.now()
            commit('times', { server, client })
            return server
          } catch (error) {
            throw error
          }
        })
    },
    register1 ({ commit, state }, data) {
      const payload = {
        clubist: encodeRegister1(data, data.recaptcha)
      }

      const subscribeData = {
        clubist: {
          ...(state.subscribeData ? state.subscribeData : {}),
          ...data
        }
      }
      delete subscribeData.clubist.recaptcha

      return new Promise((resolve, reject) => {
        try {
          commit('subscribeData', subscribeData)
          resolve(true)
        } catch (error) {
          reject(error.message)
        }
      })
        .then(() => apiRequest.mutation(requestRegister1, payload))
        .then(data => {
          if (data.clubistRegisterStep1) {
            // Erase if different informations from API
            const clubist = decodeClubistDataFlexible(data.clubistRegisterStep1)
            Object.keys(clubist).forEach(key => {
              if (clubist[key] !== null && clubist[key] !== '') {
                subscribeData.clubist[key] = clubist[key]
              }
            })

            commit('subscribeData', subscribeData)
            return true
          } else {
            throw new Error('API: inscription refused')
          }
        })
    },
    register2 ({ commit }, data) {
      const payload = {
        clubist: encodeRegister2(data)
      }

      const subscribeData = {
        clubist: data
      }

      return new Promise((resolve, reject) => {
        try {
          commit('subscribeData', subscribeData)
          resolve(true)
        } catch (error) {
          reject(error.message)
        }
      })
        .then(() => apiRequest.mutation(requestRegister2, payload))
    },
    register3 ({ commit }, data) {
      const payload = {
        clubist: encodeRegister3(data)
      }

      return apiRequest.mutation(requestRegister3, payload)
        .then(data => {
          try {
            commit('subscribeData', { clubist: {} })
            const clubist = data.clubistRegisterStep3.Clubist
            return updateClubist(commit, clubist)
          } catch (error) {
            throw error.message
          }
        })
    },
    updateStations ({ commit }) {
      return apiRequest.query(requestStations)
        .then(data => {
          try {
            commit('stations', data.stations.map(decodeStationData))
            return true
          } catch (error) {
            throw error.message
          }
        })
    },
    claimPrepaidCard ({ state, dispatch }, { station = null, address = null }) {
      const prePaidCard = {
        clubistId: state.clubist.id
      }

      if (station) {
        prePaidCard.stationId = station.id
        prePaidCard.name = station.name
        prePaidCard.address = station.street
        prePaidCard.addressComplement = station.complement
        prePaidCard.addressDistrict = station.district
        prePaidCard.addressCity = station.city
        prePaidCard.addressZipcode = station.zipcode
      } else if (address) {
        prePaidCard.address = address.street
        prePaidCard.addressComplement = address.complement
        prePaidCard.addressDistrict = address.district
        prePaidCard.addressCity = address.city
        prePaidCard.addressZipcode = address.zipcode
      }

      return apiRequest.mutation(requestClaimPrePaidCard, { prePaidCard })
        .then(() => dispatch('refreshClubist', { id: state.clubistID }))
    },
    claimUnlockCashback ({ state, dispatch }) {
      const payload = {
        cashbackClaimUnlock: {
          clubistId: state.clubist.id
        }
      }

      return apiRequest.mutation(requestClaimUnlockCashback, payload)
        .then(() => dispatch('refreshClubist', { id: state.clubistID }))
    },
    editClubist ({ commit, state, getters }, clubistData) {
      const payload = {
        clubist: encodeClubistData(Object.assign({
          id: state.clubist.id,
          cardNum: getters.cardNum
        }, clubistData))
      }

      return apiRequest.mutation(requestEditClubist, payload)
        .then(data => updateClubist(commit, data.clubistEdit))
    },
    refreshDailyAlerts ({ commit, state, getters }) {
      const cashbackAlert = state.clubist && state.clubist.cashbackAlert
      const lastAlertDate = state.lastDailyAlert || 0
      const dayMs = 1000 * 60 * 60 * 24

      // Alert display only every 24 hours
      if (cashbackAlert && lastAlertDate + dayMs < Date.now()) {
        const cashbackAlertAmount = state.clubist && state.clubist.cashbackAlertAmount
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        const cashbackAlertDate = new Date(cashbackAlert).toLocaleDateString('fr-FR', options)

        // const alertTime = new Date(cashbackAlert).getTime()
        // const nowTime = Date.now() + getters.clientDelay
        // const time = alertTime - nowTime
        // const days = Math.floor(time / dayMs)

        commit('addAlert', {
          title: 'Utilisez votre cashback',
          message: `Pour ne pas perdre vos ${cashbackAlertAmount} dirhams, effectuez un versement sur votre TotalEnergies Card prépayée avant le ${cashbackAlertDate}.`
          // message: 'Il vous reste ' + days + ' jour' + (days > 1 ? 's' : '') + ' pour débloquer votre cashback avant le retrait de 200 dirhams de votre jauge.'
        })

        // Save the last daily alert displayed
        commit('lastDailyAlert', Date.now())
      }
    },
    refreshUser ({ dispatch }) {
      return apiRequest.query(requestUser)
        .then(data => {
          const user = decodeUserData(data.user)
          if (!user.isClubist) {
            dispatch('logout')
            return new Error('User is not a clubist')
          }
          return user
        })
    },
    refreshClubist ({ commit, dispatch }, { id }) {
      return apiRequest.query(requestClubist, { id })
        .then(data => {
          updateClubist(commit, data.clubist)
          return data.clubist
        })
        .then(clubist => {
          dispatch('refreshDailyAlerts')
          return clubist
        })
        .then(clubist => {
          commit('subscribeData', { clubist: {} })
          return clubist
        })
    },
    resetPasswordRequest (_, { cardNum, recaptcha }) {
      return apiRequest.mutation(requestPasswordForgot, {
        passwordForgot: {
          idClubCard: cardNum,
          recaptcha
        }
      })
        .then(data => data.passwordForgot === true)
    },
    resetPassword (_, { hash, password, recaptcha }) {
      return apiRequest.mutation(requestPasswordReset, {
        passwordReset: {
          recaptcha,
          hash,
          password
        }
      })
    },
    login ({ commit }, { cardNum, password, recaptcha }) {
      return apiRequest.mutation(requestLogin, {
        clubist: {
          recaptcha,
          idClubCard: cardNum,
          password: password
        }
      })
        .then(data => {
          try {
            const clubist = data.clubistLogin.Clubist
            commit('clubist', { clubist: decodeClubistData(clubist) })
            commit('subscribeData', { clubist: {} })

            return true
          } catch (error) {
            throw error.message
          }
        })
    },
    logout ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        apiRequest.mutation(requestLogout)
          .then(data => {
            dispatch('refreshUser')
              .catch(() => 1) // Silent error
            return data.clubistLogout
          })
          .then(data => resolve(data))
          .catch(error => reject(error))
          .finally(() => {
            commit('logout')
          })
      })
    }
  }
})
