<template>
  <transition name="modal-transition">
    <div v-if="show" class="modal-background" @click="close">
      <div class="modal-content" @click.stop>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.modal-background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 50px 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 100;
  overflow-y: auto;

  /* IE11 */
  *::-ms-backdrop, & {
    display: block;
  }
}
.modal-content {
  background-color: #fff;
  margin: auto;
  width: calc(100% - 70px);
  max-width: 640px;
  text-align: center;
  padding: 50px 25px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  color: $blue;
  p {
    margin: 0 0 25px;
    font-size: 18px;
  }
  .card {
    font-size: 35px;
    font-weight: 800;
    margin-bottom: 25px;
  }
  .text-lg {
    font-size: 20px;
    font-weight: 700;
  }
  .text-xl {
    font-size: 24px;
    font-weight: 700;
  }
}
.modal-transition-enter,
.modal-transition-leave-active {
  opacity: 0;
  .modal-content {
    transform: translateY(40px);
  }
}

.modal-transition-enter-active,
.modal-transition-leave-active {
  transition: opacity 0.5s ease;
  .modal-content {
    transition: transform 0.5s ease;
  }
}
</style>
