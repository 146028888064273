import ApolloClient from 'apollo-boost'
import EventEmitter from './EventEmitter'

/**
 * Check if response contain a data object
 *
 * @param {Object} response
 */
const getResponseData = response => {
  if (!response.data) {
    throw new Error('api.dataUndefined')
  }

  return response.data
}

/**
 * Singleton to call GraphQL API
 */
export default class ApiRequest extends EventEmitter {
  constructor () {
    if (ApiRequest.INSTANCE) {
      return ApiRequest.INSTANCE
    }

    super()

    ApiRequest.INSTANCE = this

    this.apolloClient = new ApolloClient({
      uri: process.env.VUE_APP_GRAPHQL_ENDPOINT
    })
  }

  /**
   * Error parser to dispatch event
   *
   * @param {Object} error
   */
  parseError (error) {
    const list = error.errors || error.graphQLErrors
    const errorTag = list && list[0] && list[0].tag
    if (errorTag) {
      if (errorTag === 'login_required') {
        this.emit('access-denied')
      }
      return 'gql.' + errorTag
    } else {
      return 'api.serverError'
    }
  }

  /**
   * Request a GraphQL query
   *
   * @param {DocumentNode} query
   * @param {Object} variables
   * @param {String} fetchPolicy
   */
  query (query, variables, fetchPolicy = 'network-only') {
    return this.apolloClient.query({
      query,
      variables,
      fetchPolicy
    })
      .then(getResponseData)
      .catch(error => {
        throw new Error(this.parseError(error))
      })
  }

  /**
   * Request a GraphQL mutation
   *
   * @param {DocumentNode} query
   * @param {Object} variables
   * @param {Object} refetchQueries
   */
  mutation (mutation, variables, refetchQueries = undefined) {
    return this.apolloClient.mutate({
      mutation,
      variables,
      refetchQueries
    })
      .then(getResponseData)
      .catch(error => {
        throw new Error(this.parseError(error))
      })
  }
}
