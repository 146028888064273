<template>
  <div class="menu__container">
    <div class="menu menu-desktop">
      <MenuContent class="container is-desktop" />
    </div>
    <slot class="menu__right" />
  </div>
</template>

<script>
import MenuContent from '@/components/MenuContent.vue'

export default {
  components: {
    MenuContent
  }
}
</script>

<style lang="scss" scoped>
.menu__container {
  display: flex;
  min-height: calc(100vh - 70px);

  &>*:last-child {
    flex: 1;
  }
}
.menu {
  background-color: $red;
  background-image: url(~@/assets/svg/global-bg.svg);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 200%;
  color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.4);
  display: none;

  ::v-deep {
    li {
      font-size: 16px;

      .link {
        opacity: 0.5;
        padding: 15px 65px 15px 30px;
      }
      .router-link-exact-active {
        background: #FFF;
        color: $red;
        opacity: 1;

        &:after {
          background-image: url('~@/assets/svg/next-red.svg');
        }
      }
    }
  }
}

@include media('>large') {
  .menu {
    display: flex;
  }
}
</style>
