<template>
  <div id="app" :class="{ 'logged-in': $route.meta && $route.meta.requiresAuth }">

    <SiteHeader @toggleMenu="toggleMenu" :menuOpened="isMenuOpened"></SiteHeader>

    <MenuMobile
      v-if="$route.meta && $route.meta.requiresAuth"
      @closeMenu="closeMenu"
      :menuOpened="isMenuOpened"
    ></MenuMobile>

    <transition-page>
      <router-view v-if="displayPage"/>
    </transition-page>

    <Modals />

    <Alert :data="alert" />

  </div>
</template>

<style lang="scss">
#app {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: 'Roboto';
  font-size: 16px;
  background-color: $red;
  color: #fff;
  background-image: url(~@/assets/svg/global-bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom -10% center;

  // IE11 fix
  _:-ms-fullscreen, :root & {
    background-image: url(~@/assets/images/global-bg.png);
  }
  @include media('>tablet') {
    background-size: cover;
    background-position: bottom 64% center;
  }
  &.logged-in {
    background-image: none;
  }

  @media screen and (min-width: 2000px) {
    .site-header, .page {
      padding: 0 calc(50% - 975px);
    }
  }
}

</style>

<script>
import { mapGetters } from 'vuex'
import SiteHeader from '@/components/SiteHeader.vue'
import MenuMobile from '@/components/MenuMobile.vue'
import TransitionPage from '@/components/TransitionPage.vue'
import Modals from '@/components/Modals.vue'
import Alert from '@/components/Alert.vue'
import ApiRequest from '@/pure/api-requests.js'
import { dateToStdDate, stdDateToSmallFrDate, getStartCurrentLotteryDate, getEndCurrentLotteryDate, getLotteryDate } from '@/pure/dates.js'

export default {
  components: {
    SiteHeader,
    MenuMobile,
    TransitionPage,
    Modals,
    Alert
  },

  data () {
    return {
      isMenuOpened: false
    }
  },

  computed: {
    ...mapGetters([
      'alert',
      'isWinAlert',
      'isDrawAlert'
    ]),

    ...mapGetters({
      latestLottery: 'lottery/latestLottery',
      latestLotteryWinnerInfo: 'lottery/latestLotteryWinnerInfo'
    }),

    displayPage () {
      // Wait for display pages with data to avoid empty data in first display
      return !(this.$route.meta && this.$route.meta.requiresAuth) || this.$store.state.clubist
    }
  },

  watch: {
    $route: 'testUserAccess'
  },

  created () {
    new ApiRequest().on('access-denied', this.accesDenied)

    this.$eventHub.$on('refresh-clubist', this.refreshClubist)

    this.$store.dispatch('updateServerTime')
      .catch(() => console.warn('Can not initialize times'))

    // Login test
    this.$store.dispatch('refreshUser')
      .then(user => {
        if (
          !(this.$route.meta && this.$route.meta.requiresAuth) &&
          (this.$route.name === 'landing' || this.$route.name === 'login')
        ) {
          this.$router.push({ name: 'dashboard' })
        }

        this.$eventHub.$emit('refresh-clubist', user.clubistId)
      })
      .catch(() => 1) // hide errors

    // Deny access to banned user
    this.$store.watch(
      (state, getters) => getters.isBanned,
      (newValue, oldValue) => this.testUserAccess()
    )
  },

  destroyed () {
    this.$eventHub.$off('refresh-clubist', this.refreshClubist)
    new ApiRequest().off('access-denied', this.accesDenied)
  },

  methods: {
    testUserAccess () {
      if (this.$store.getters.isBanned && this.$route.meta && this.$route.meta.requiresAuth) {
        this.$router.push({ name: 'login' })
      }
    },

    refreshClubist (clubistID = 0) {
      const id = clubistID || this.$store.state.clubistID
      if (id) {
        Promise.all([
          this.$store.dispatch('refreshClubist', { id }),
          this.$store.dispatch('lottery/refreshLotteryWinnerInfo', { clubistId: id, isConfirmed: true }),
          this.$store.dispatch('lottery/refreshLatestLotteries', { count: 3, isConfirmed: true })
        ])
          .then(() => {
            this.showWinnerPop()
            this.showNonWinnerPop()
            return true
          })
          .catch(error => console.warn(error.message))
      }
    },

    accesDenied () {
      if (this.$route.meta && this.$route.meta.requiresAuth && this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      }
    },

    toggleMenu () {
      this.isMenuOpened = !this.isMenuOpened
    },

    closeMenu () {
      this.isMenuOpened = false
    },

    getCurrentLotteryRange () {
      const today = new Date(Date.now() + this.$store.getters.clientDelay)
      return {
        startDate: dateToStdDate(getStartCurrentLotteryDate(today)),
        endDate: dateToStdDate(getEndCurrentLotteryDate(today))
      }
    },

    /**
     * Display winner pop if:
     * - backend ask to display (this.isWinAlert)
     * - countdown is ended
     * Or wait the countdown before display
     */
    showWinnerPop () {
      // Detect if the countdown is finish before display winner
      const canDisplayWinner = () => {
        if (!this.latestLotteryWinnerInfo) {
          return false
        }
        try {
          const now = new Date(Date.now() + this.$store.getters.clientDelay)
          const currentLotteryEndDate = getLotteryDate(now)
          const winnerLotteryEndDate = getLotteryDate(new Date(this.latestLotteryWinnerInfo.endDate))
          return winnerLotteryEndDate.getTime() < currentLotteryEndDate.getTime()
        } catch (error) {
          return false
        }
      }

      // Time + 1 sec before display current winner
      const delayToDisplayWinner = () => {
        // If the user is not winner return 1 day
        if (!this.latestLotteryWinnerInfo) {
          return 1000 * 60 * 60 * 24
        }
        const now = new Date(Date.now() + this.$store.getters.clientDelay)
        const winnerLotteryEndDate = getLotteryDate(new Date(this.latestLotteryWinnerInfo.endDate))
        return winnerLotteryEndDate.getTime() + 10 - (now.getTime())
      }

      // Display pop winner
      const displayWinner = () => {
        if (this.isWinAlert && this.latestLotteryWinnerInfo) {
          this.$store.commit('addAlert', {
            title: `Félicitations !`,
            message: `Vous avez remporté le tirage au sort de la période du ${stdDateToSmallFrDate(this.latestLotteryWinnerInfo.startDate)} au ${stdDateToSmallFrDate(this.latestLotteryWinnerInfo.endDate)}`,
            additional: 'Nous vous contacterons bientôt pour vous remettre votre cadeau.',
            bgImg: null,
            bgColor: null,
            type: 'lottery-winner',
            onClosed: () => this.$store.dispatch('editClubist', { winAlert: false })
          })
        }
      }

      // Display winner or wait time to display
      if (canDisplayWinner()) {
        displayWinner()
      } else if (delayToDisplayWinner() > 0) {
        clearTimeout(this.showWinnerPopTimeout)
        this.showWinnerPopTimeout = setTimeout(displayWinner, delayToDisplayWinner())
      }
    },

    /**
     * Display winner pop if:
     * - backend ask to display (this.isDrawAlert)
     * - countdown is ended
     * Or wait the countdown before display
     */
    showNonWinnerPop () {
      // Detect if the countdown is finish before display winner
      const canDisplayWinner = () => {
        try {
          const now = new Date(Date.now() + this.$store.getters.clientDelay)
          const currentLotteryEndDate = getLotteryDate(now)
          const winnerLotteryEndDate = getLotteryDate(new Date(this.latestLottery.endDate))

          return winnerLotteryEndDate.getTime() < currentLotteryEndDate.getTime()
        } catch (error) {
          return false
        }
      }

      // Time + 1 sec before display current winner
      const delayToDisplayWinner = () => {
        const now = new Date(Date.now() + this.$store.getters.clientDelay)
        const winnerLotteryEndDate = getLotteryDate(new Date(this.latestLottery.endDate))

        return winnerLotteryEndDate.getTime() + 10 - (now.getTime())
      }

      // Display pop winner
      const displayWinner = () => {
        if (this.isDrawAlert) {
          const { startDate, endDate } = this.getCurrentLotteryRange()
          this.$store.commit('addAlert', {
            title: `Le gagnant de cette semaine est ${this.latestLottery.clubist.firstName} ${this.latestLottery.clubist.lastName}`,
            message: `Tentez votre chance lors du prochain tirage au sort du ${stdDateToSmallFrDate(startDate)} au ${stdDateToSmallFrDate(endDate)}.<br>
            1 an de carburant à gagner chaque semaine !`,
            bgImg: null,
            bgColor: null,
            onClosed: () => this.$store.dispatch('editClubist', { drawAlert: false })
          })
        }
      }

      // Display winner or wait time to display
      if (canDisplayWinner()) {
        displayWinner()
      } else if (delayToDisplayWinner() > 0) {
        clearTimeout(this.showNonWinnerPopTimeout)
        this.showNonWinnerPopTimeout = setTimeout(displayWinner, delayToDisplayWinner())
      }
    }
  }
}
</script>
