<template>
  <footer class="footer-large">
    <div>
      <router-link class="faq-router-link" :to="{ name: 'landing-faq' }">
        <img class="faq-image" src="../assets/svg/help.svg" alt="?" width="21" height="21" />
        <p class="faq-tagline" v-html="$t('landing.faq.title')"></p>
      </router-link>

      <ContactLinkLarge class="contact-link-custom" />
    </div>
    <div class="footer-large__links">
      <div class="footer-large__socials">
        <strong class="follow-us">Suivez nous</strong>
        <a href="https://www.facebook.com/TotalMaroc" target="_blank" rel="noopener" class="social-link">
          <img src="../assets/social/facebook.png" srcset="../assets/social/facebook.png, ../assets/social/facebook-2x.png 2x" alt="Facebook" width="30" height="30" >
        </a>
        <a href="https://twitter.com/TotalEnergiesFR" target="_blank" rel="noopener" class="social-link">
          <img src="../assets/social/twitter.png" srcset="../assets/social/twitter.png, ../assets/social/twitter-2x.png 2x" alt="Twitter" width="30" height="30" >
        </a>
        <a href="https://www.linkedin.com/company/totalenergies/" target="_blank" rel="noopener" class="social-link">
          <img src="../assets/social/linkedin.png" srcset="../assets/social/linkedin.png, ../assets/social/linkedin-2x.png 2x" alt="Linkedin" width="30" height="30" >
        </a>
        <a href="https://www.youtube.com/channel/UC8oOuOtCiz7D2CrTS7_qmpg" target="_blank" rel="noopener" class="social-link">
          <img src="../assets/social/youtube.png" srcset="../assets/social/youtube.png, ../assets/social/youtube-2x.png 2x" alt="Youtube" width="44" height="30" >
        </a>
        <a href="https://www.instagram.com/totalenergiesmaroc/" target="_blank" rel="noopener" class="social-link">
          <img src="../assets/social/instagram.png" srcset="../assets/social/instagram.png, ../assets/social/instagram-2x.png 2x" alt="Instagram" width="30" height="30" >
        </a>
      </div>
      <div class="footer-large__market">
        <a v-if="!isAndroid" href="https://apps.apple.com/us/app/totalenergies-maroc/id448196713" target="_blank" rel="noopener" class="market-link">
          <img src="../assets/market/app-store.png" srcset="../assets/market/app-store.png, ../assets/market/app-store-2x.png 2x" alt="App Store" width="169" height="50" >
        </a>
        <a v-if="!isIos" href="https://play.google.com/store/apps/details?id=mlu.myandroid.stationstotal&hl=en&gl=US" target="_blank" rel="noopener" class="market-link">
          <img src="../assets/market/google-play.png" srcset="../assets/market/google-play.png, ../assets/market/google-play-2x.png 2x" alt="Google Play" width="169" height="50" >
        </a>
      </div>
      <div>
        <FooterLinks />
      </div>
    </div>
  </footer>
</template>

<script>
import ContactLinkLarge from '@/components/ContactLinkLarge.vue'
import FooterLinks from '@/components/FooterLinks.vue'
import { IS_ANDROID, IS_IOS } from '../pure/device'

export default {
  name: 'FooterLarge',

  components: {
    ContactLinkLarge,
    FooterLinks
  },

  data () {
    return {
      isAndroid: IS_ANDROID,
      isIos: IS_IOS
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-large {
  padding: 40px 32px;
  width: 100%;
  background-color: #fff;
  text-align: center;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .faq-router-link {
    display: block;
    margin-bottom: 16px;
    text-decoration: none;
    cursor: pointer;

    .faq-image {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
    }

    .faq-tagline {
      display: inline-block;
      color: $red;
      font-weight: bold;
      text-align: center;
    }
  }
}
.footer-large__links {
  color: $red;
}
.footer-large__socials {
  &>* {
    display: inline-block;
    vertical-align: middle;
    margin: 0.25em 0.5em;
  }
}
.follow-us {
  font-weight: bold;
  font-size: 18px;
  display: block;
  margin: 1.6em 0 0.8em;
}

.contact-link-custom {
  padding: 0.5em 1.2em;
  ::v-deep p {
    font-size: 16px;
    margin: 0.6em 0;
  }
}

.social-link {
}

.footer-large__market {
  margin: 22px;
  .market-link {
    display: inline-block;
    margin: 5px 10px;
  }
}

@include media(">=desktop") {
  .footer-large {
    flex-direction: row;
  }
  .footer-large__links {
    min-width: 630px;
  }
  .follow-us {
    display: inline;
    margin: 0.5em;
  }
}
</style>
