<template>
  <ContactLink class="contact-link-large" :disableStyle="true">
    <p>Contactez le Service Client au</p>
    <img src="../assets/svg/phone.svg" alt="téléphone" width="26" height="24" />
    <strong>{{ phone }}</strong>
    <p>du Lundi au Samedi de 8H&nbsp;à&nbsp;19H</p>
  </ContactLink>
</template>

<script>
import ContactLink from '@/components/ContactLink.vue'
import { config } from '@/config.js'

export default {
  components: { ContactLink },

  data () {
    return {
      phone: config.contact.phone
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-link-large {
  display: inline-block;
  margin: 0;
  color: #fff;
  text-align: center;
  padding: 8px 36px;
  background-color: $red;
  text-decoration: none;
  border: 0;
  font-size: 18px;

  p {
    line-height: 1.5;
    margin: 0;
  }

  img {
    display: inline-block;
    height: 20px;
  }

  strong {
    margin-left: 24px;
    font-size: 24px;
    font-weight: bold;
  }

  @include media('<phone') {
    padding: 8px 24px;
    p {
      font-size: 16px;
    }
    strong {
      font-size: 20px;
    }
  }
}
</style>
