import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)

function isIOS () {
  const list = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
  return (
    list.includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

function isAndroid () {
  const userAgent = navigator.userAgent.toLowerCase()
  return userAgent.indexOf('android') > -1
}

export const IS_MOBILE = browser.getPlatformType() === 'mobile'
export const IS_DESKTOP = !IS_MOBILE
export const IS_IOS = isIOS()
export const IS_ANDROID = isAndroid()
