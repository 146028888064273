import errorsCodes from '../../graphql-sdl/errors_codes_fr.json'

export default {
  landing: {
    title: `Votre espace TotalEnergies Club`,

    create: {
      resume: `Vous êtes membre de l’ancien programme TotalEnergies Club, inscrivez-vous sur le nouveau programme pour découvrir vos avantages`,
      button: `Je crée mon compte`
    },
    signup: {
      resume: `Vous souhaitez créer un compte sur le nouveau programme TotalEnergies Club et activer votre carte, inscrivez-vous et découvrez vos avantages`,
      button: `Je m'inscris`
    },
    signin: {
      resume: `Vous êtes membre du nouveau programme TotalEnergies Club et vous avez déjà un compte`,
      button: `J'accède à mon compte`
    },
    faq: {
      title: `Questions fréquentes`
    }
  },

  landingFaq: {
    title: `Questions fréquentes`,
    list: [
      {
        question: `Comment puis-je m'inscrire sur le nouveau programme TotalEnergies Club ?`,
        content: `<p>Pour s’inscrire au nouveau programme TotalEnergies Club :</p>
        <ul>
          <li>Récupérez votre carte auprès d’une station-service TotalEnergies « elle est immédiatement opérationnelle ».</li>
          <li>Inscrivez-vous obligatoirement sur le site : www.club.totalenergies.ma ou appelez le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H, pour bénéficier des avantages « Cashback et Tombola hebdomadaire ».</li>
        </ul>
        <p>Vous aurez besoin de votre numéro de carte de fidélité « 19 chiffres au verso de votre carte » au moment de l’inscription.</p>
        <img src="/img/image/total-maroc-club-card-x1.png" srcset="/img/image/total-maroc-club-card-x1.png, /img/image/total-maroc-club-card-x2.png 2x" width="298" height="197" alt="carte TotalEnergies Club">`
      },
      {
        question: 'Je ne connais pas le numéro de ma carte Club.',
        content: `<p>Le numéro de votre carte est indiqué au verso de la carte, sous le code-barres et il contient 19 chiffres (voir image présentée ci-dessus). Le ticket de votre transaction mentionne également votre numéro de carte Club.</p>`
      },
      {
        question: `Je n’ai pas trouvé la réponse à ma question. Que puis-je faire ?`,
        content: `<p>Veuillez contacter le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés).</p>`
      }
    ]
  },

  faq: {
    title: `Questions fréquentes sur le nouveau programme CLUB TotalEnergies`,
    content: [
      {
        title: `Questions fréquentes sur le nouveau programme CLUB TotalEnergies`,
        list: [
          {
            question: `Comment puis-je m'inscrire sur le nouveau programme CLUB TotalEnergies ?`,
            content: `<p>Pour s’inscrire au nouveau programme CLUB TotalEnergies :</p>
            <ul>
              <li>Récupérez votre carte auprès d’une station-service TotalEnergies « elle est immédiatement opérationnelle ».</li>
              <li>Inscrivez-vous obligatoirement sur le site : www.club.totalenergies.ma ou appelez le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H, pour bénéficier des avantages « Cashback et Tombola hebdomadaire ».</li>
            </ul>
            <p>Vous aurez besoin de votre numéro de carte de fidélité « 19 chiffres au verso de votre carte » au moment de l’inscription.</p>
            <img src="/img/image/total-maroc-club-card-x1.png" srcset="/img/image/total-maroc-club-card-x1.png, /img/image/total-maroc-club-card-x2.png 2x" width="298" height="197" alt="carte TotalEnergies Club">`
          },
          {
            question: `Comment cumuler des dirhams ?`,
            content: `<p>Les dirhams sont cumulés lors de vos achats « Carburant – huiles de moteur » dans les stations-service TotalEnergies.</p>`
          },
          {
            question: `Comment booster ma cagnotte de dirhams ?`,
            content: `<p>Vous cumulez plus de dirhams et plus rapidement, en consommant : les carburants Excellium et les huiles de moteur TotalEnergies.</p>`
          },
          {
            question: `Comment puis-je consulter ma cagnotte de dirhams ?`,
            content: `<p>Vous pouvez consulter le solde de vos dirhams cumulé en :</p>
            <ul>
              <li>Vous connectant sur www.club.totalenergies.ma</li>
              <li>Via l'application mobile TotalEnergies Marketing Maroc.</li>
              <li>Appelant le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H.</li>
            </ul>`
          },
          {
            question: `Puis-je utiliser une seule carte pour plusieurs véhicules ?`,
            content: `<p>La carte CLUB TotalEnergies doit être associée à un seul véhicule.</p>
            <p>Si vous disposez de plusieurs véhicules, il convient d’associer à chacun sa carte CLUB TotalEnergies.</p>`
          },
          {
            question: `Je ne connais pas le numéro de ma carte CLUB TotalEnergies.`,
            content: `<p>Le numéro de votre carte est indiqué au verso de la carte, sous le code-barres et il contient 19 chiffres. Le ticket de votre transaction mentionne également votre numéro de carte CLUB TotalEnergies.</p>
            <img src="/img/image/total-maroc-club-card-x1.png" srcset="/img/image/total-maroc-club-card-x1.png, /img/image/total-maroc-club-card-x2.png 2x" width="298" height="197" alt="carte TotalEnergies Club">`
          },
          {
            question: `Les dirhams cumulés ont-ils une durée de vie ?`,
            content: `<p>La durée de validité d’une cagnotte ayant atteint 200 dirhams est de 12 (douze) mois glissants à partir du 31 Décembre 2020.</p>
            <p>Par ailleurs, si aucune transaction carburant et/ou huiles de moteur n’a été effectué au cours des 12 derniers mois, votre carte sera ainsi inactive et les règles de péremption des dirhams cumulés s’appliquent à la Totalité du solde disponible sur la cagnotte sans condition de minimum de dirhams dans votre cagnotte. Il y aura donc une remise à zéro Totale du montant de la cagnotte.</p>
            <p>Pour connaître la date de la dernière présentation de votre carte TotalEnergies Club en stations-service :</p>
            <ul>
              <li>Le ticket de votre dernière transaction mentionne la date de votre transaction.</li>
              <li>Ou un simple appel au 0801 000 023 du Lundi au Samedi de 8H à 19H.</li>
            </ul>`
          },
          {
            question: `Je n’arrive pas à débloquer mes dirhams.`,
            content: `<p>Veuillez contacter le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés).</p>`
          },
          {
            question: `Comment puis-je bénéficier de mes dirhams cumulés ?`,
            content: `<p>Il est possible de débloquer les dirhams cumulés, à partir du premier palier de 50 dirhams atteint, sur une TotalEnergies Card Prépayée livrée une seule fois gratuitement par TotalEnergies Marketing Maroc. Cette conversion est possible directement via:</p>
            <ul>
              <li>Le site : www.club.totalenergies.ma</li>
              <li>Ou via le service client au 0801 000 023 du lundi au samedi de 8H à 19H (hors jours fériés).</li>
            </ul>
            <p>La TotalEnergies Card Prépayée est ensuite envoyée en point-relais ou à l’adresse de votre choix. La carte est personnalisée, envoyée une seule fois et rechargeable à chaque déblocage de paliers à votre demande.</p>`
          },
          {
            question: `Qu'est-ce qu'une livraison en point relais ?`,
            content: `<p>Les Points Relais sont des commerces de proximité (librairies, tabac/presse, épiceries...) qui sont sélectionnés par ville pour plus de proximité.</p>
            <p>Ils réceptionnent et conservent votre colis contenant votre TotalEnergies Card prépayée, vous laissant ainsi la liberté de les retirer sans rendez-vous au moment qui vous convient !</p>
            <p>Vous êtes informés de l’arrivée de votre colis en Point Relais par sms.</p>
            <p>Vous disposez de 14 jours pour le récupérer sans rendez-vous pendant les horaires d'ouverture du Point Relais. Au-delà de 14 jours, votre colis sera retourné.</p>
            <p>NB :</p>
            <ul>
              <li>La livraison de votre colis se fait au point relais de votre choix.</li>
              <li>Une pièce d'identité vous sera réclamée lors du retrait de votre colis au Point Relais.</li>
            </ul>`
          },
          {
            question: `Comment être éligibles à la Tombola hebdomadaire de 1 an de carburant ?`,
            content: `<p>Pour être éligible, il faut :</p>
            <ul>
              <li>Être inscrit sur www.club.totalenergies.ma</li>
              <li>Avoir consommé un total de litres « tout type de carburant » supérieur ou égal à 15 litres au cours de la semaine.</li>
            </ul>`
          },
          {
            question: `Je n’ai pas trouvé la réponse à ma question. Que puis-je faire ?`,
            content: `<p>Veuillez contacter le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés).</p>`
          }
        ]
      },
      {
        title: `Questions fréquentes sur mes données personnelles`,
        list: [
          {
            question: `Où puis-je consulter et changer mes coordonnées et données personnelles ?`,
            content: `<p>Sur base de votre numéro de carte CLUB TotalEnergies, vous pouvez à tout moment modifier vos données en appelant le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés).</p>`
          },
          {
            question: `J’essaie d’obtenir le code PIN de ma TotalEnergies Card prépayée mais je n’y parviens pas.`,
            content: `<p>Téléchargez l’application TotalEnergies Marketing Maroc et renseigner les champs sur l’onglet Carte Prépayée :</p>
            <ul>
              <li>Nom & prénom</li>
              <li>Numéro de votre TotalEnergies Card Prépayée</li>
              <li>Numéro de votre GSM « Associé à votre compte CLUB TotalEnergies»</li>
            </ul>
            <img src="/img/image/total-maroc-prepayed-card-1x.png" srcset="/img/image/total-maroc-prepayed-card-1x.png, /img/image/total-maroc-prepayed-card-2x.png 2x" width="298" height="197" alt="carte TotalEnergies Club">
            <p>Vous recevrez directement votre Code PIN par SMS. À partir de cette application, vous pourrez, à tout moment, consulter votre solde et faire le suivi de vos consommations.</p>
            <p>Vous pouvez également contacter le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés) pour récupérer votre code PIN.</p>`
          }
        ]
      },
      {
        title: `Questions fréquentes Carte perdue ou volée`,
        list: [
          {
            question: `Que faire en cas de perte de ma carte CLUB TotalEnergies?`,
            content: `<p>Si vous avez perdu votre carte CLUB TotalEnergies:</p>
              <ul>
                <li>Récupérez une nouvelle carte CLUB TotalEnergies en station</li>
                <li>Appelez le service client au 0801 000 023 du Lundi au Samedi de 8H à 19H (hors jours fériés) pour bloquer l’ancienne carte et associer la nouvelle.</li>
                <li>Le montant des dirhams cumulés sur votre cagnotte sera transféré automatiquement vers la nouvelle carte CLUB TotalEnergies.</li>
              </ul>`
          },
          {
            question: `Que faire en cas de perte de ma TotalEnergies Card prépayée ?`,
            content: `<p>En cas de perte ou de vol de sa Carte TotalEnergies Card Prépayée, vous devez immédiatement faire opposition :</p>
            <ul>
              <li>Par simple appel au 0801 000 023 du lundi au samedi de 8H à 19H (hors jours fériés).</li>
            </ul>
            <p>La carte est envoyée la première fois gratuitement. En cas de perte ou de vol, nous vous invitons à faire une nouvelle demande de carte Prépayée via le service client, qui sera à vos frais.</p>
            <p>Une fois que, la nouvelle demande de carte prépayée sera réalisée, le transfert du solde de votre ancienne carte vers votre nouvelle carte se fera automatiquement.</p>`
          }
        ]
      }
    ]
  },

  register1: {
    title: `Saisissez votre n° de carte Club`,
    resume: `Si vous ne possédez pas de carte Club, rendez-vous en station TotalEnergies pour récupérer votre Welcome Pack`,
    name: {
      label: `Nom*`,
      field: `nom`
    },
    card: {
      label: `N° de carte TotalEnergies Club*`,
      field: `carte`
    }
  },

  boosterExplanation: {
    content: '<strong>Boostez votre cagnotte</strong> en consommant les <strong>carburants Excellium</strong> et les <strong>lubrifiants TotalEnergies</strong> pour gagner plus de cash et plus rapidement !'
  },

  prepaid: {
    success: {
      title: `Merci !`,
      message: `Votre commande de TotalEnergies Card prépayée est en cours de traitement ainsi que le déblocage de cashback disponible.<br><br>Vous la recevrez dans les plus brefs délais.`
    }
  },

  prepaidAndCashbackUnlock: {
    success: {
      title: `Merci !`,
      message: `Votre commande de TotalEnergies Card prépayée et votre demande de déblocage sont en cours de traitement.`
    }
  },

  winDhmsExplanation: {
    title: `Comment gagner des dirhams&nbsp;?`,
    part1: `Je fais une <strong>transaction carburant ou lubrifiant</strong>`,
    part1booster: `Effet <strong>booster</strong> avec les <strong>carburants Excellium</strong> et les <strong>lubrifiants TotalEnergies</strong>`,
    part2: `J’utilise ma <strong>carte TotalEnergies Club</strong> associée à mon véhicule* lors de chaque transaction`,
    part3: `<Strong>Je cumule des dirhams</Strong> dans ma jauge`,
    part4: `Je transfère mes dirhams sur&nbsp;ma <div><strong>TotalEnergies Card prépayée</strong></div>`,
    part5: `Je consomme mes dirhams gagnés pour tout <strong>achat en station-service</strong>`
  },

  reclamation: {
    card: {
      title: `Carte perdue ou volée&nbsp;?`,
      message: `Veuillez contacter le Service Client pour la démarche à suivre.`
    },
    cashback: {
      title: `Question sur votre cashback&nbsp;?`,
      message: `Si vous avez une question concernant votre cagnotte, vous pouvez contacter le service client.`
    },
    contactBtn: `Contacter le Service Client`
  },

  modals: {
    clubCard: {
      helpNum: {
        resume: `Vous pourrez retrouver votre numéro de carte au
        <strong>dos de celle-ci.</strong>`,
        ok: `J'ai compris`
      }
    }
  },

  resetPassword: {
    title: `Réinitialisation de mot de passe`,
    message: `Vous avez demandé à réinitialiser votre mot de passe.<br>Veuillez en entrer un nouveau ci-dessous.`,
    password: {
      label: `Nouveau mot de passe*`
    },
    requirements: {
      title: `Doit contenir au moins`,
      list: [
        `8 caractères`,
        `1 chiffre`,
        `1 majuscule`,
        `1 minuscule`
      ]
    },
    ask: {
      success: `Vous recevrez d'ici quelques instants un SMS de réinitialisation`
    }
  },
  gql: errorsCodes,
  api: {
    serverError: `Erreur du serveur, veuillez réessayer plus tard`,
    dataUndefined: `Aucune données retournées par le serveur, veuillez réessayer plus tard`
  }
}
