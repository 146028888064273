<template>
  <div class="footer-links">
    <button @click="$eventHub.$emit('modal-legals')" class="btn btn-underline--red small-text">
      Mentions Légales</button>
    <button @click="$eventHub.$emit('modal-conditions')" class="btn btn-underline--red small-text">
      Conditions Générales de Vente</button>
    <a href="mailto:contact.btoc.ma@totalenergies.co.ma" target="_blank" rel="noopener" class="btn btn-underline--red small-text">
      Contact</a>
    <a href="https://www.totalenergies.ma" target="_blank" rel="noopener" class="btn btn-underline--red small-text">
      www.totalenergies.ma</a>
  </div>
</template>

<script>
import { config } from '@/config.js'

export default {
  name: 'FooterLinks',

  data () {
    return {
      emailTotal: config.contact.email
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-links {
  width: 100%;
  text-align: center;
  line-height: 1.75em;
}
.btn {
  opacity: 1;
  transition: none;
  display: inline-block;
  width: auto;
  &:hover {
    opacity: 1;
  }
}
.small-text {
  text-transform: none;
  font-weight: 400;

  &:after {
    content: "|";
    text-decoration: none;
    margin: 0 0.5em;
    pointer-events: none;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

@include media(">=desktop") {
  // .small-text {
  //   &:after {
  //     content: "\a";
  //     white-space: pre;
  //   }
  // }
}
</style>
