<template>
  <div class="dashboard-page page">
    <MenuDesktop
      v-if="$route.meta && $route.meta.requiresAuth"
    >
      <div class="grid-container">
        <!-- Welcome block -->
        <section class="section-header">
          <div class="container">
            <h1>Bienvenue {{ firstName }}&nbsp;!</h1>
            <p class="tagline">
              Via cette interface, vous pouvez accéder à votre cagnotte et transférer vos dirhams gagnés sur votre TotalEnergies Card prépayée
            </p>
            <div v-if="isTaxi" class="profile profile-taxi">
              <span>Taxi</span>
            </div>
            <div v-else-if="isCar" class="profile profile-auto">
              <span>Automobiliste</span>
            </div>
            <div v-else class="profile profile-pro">
              <span>Poids Lourd</span>
            </div>
          </div>
        </section>

        <div class="blocks">
          <!-- Gauge block -->
          <div class="jauge-concours">
            <section v-loading="isJaugeLoading" class="section-jauge">
              <div class="container">
                <h2>Votre jauge</h2>
                <DirhamCounter :cashback="cashback"></DirhamCounter>
                <ul class="jauge-legend">
                  <li class="legend-bloque">Dirhams à débloquer</li>
                  <li class="legend-dirhams">Dirhams cumulés</li>
                </ul>

                <p
                  class="cashing"
                  v-if="isCashing"
                >
                  Retrait de {{cashingAmount}} dhs en cours
                </p>
                <p v-else-if="isPrepaidCardClaimOnDelivery" class="soon-claim-dirham">
                  Votre commande de TotalEnergies Card prépayée et votre demande de déblocage sont en cours de traitement.
                </p>
                <p v-else-if="dirhamsToUnlock < 50 && !prepaidCardNum" class="soon-claim-dirham">
                  Vous pourrez commander une TotalEnergies Card prépayée dès que vous aurez cumulé 50 DHS
                </p>
                <button
                    class="btn btn-inverse"
                    v-else-if="hasAlreadyClaimFirstPrepaidCard && !prepaidCardNum"
                    @click="$eventHub.$emit('modal-no-secondary-prepaid-card')"
                    :disabled="statusID !== 0"
                    :class="{ disabled: statusID !== 0 }"
                  >
                    Débloquer {{dirhamsToUnlock}} DHS
                </button>
                <button
                    class="btn btn-inverse"
                    v-else-if="!prepaidCardNum"
                    @click="$eventHub.$emit('modal-no-prepaid-card')"
                    :disabled="statusID !== 0"
                    :class="{ disabled: statusID !== 0 }"
                  >
                    Débloquer {{dirhamsToUnlock}} DHS
                </button>
                <button
                  class="btn btn-inverse"
                  v-else-if="dirhamsToUnlock > 0"
                  @click="$eventHub.$emit('modal-cashback-unlock')"
                  :disabled="statusID !== 0"
                  :class="{ disabled: statusID !== 0 }"
                >
                  Débloquer {{dirhamsToUnlock}} DHS
                </button>

                <div class="statut">
                  <button v-if="statusID === 1" @click="$eventHub.$emit('modal-status-alert')" class="status-link">Statut: {{status}}</button>
                  <span v-else class="status-link">Statut: {{status}}</span>
                </div>
              </div>
            </section>

            <div class="jauge-rules">
              <img class="jauge-rules-warning" alt="Attention" src="@/assets/svg/warning.svg" />

              <p class="jauge-rules-text">
                <b>*Attention: 1 carte Club</b> ne peut être associée <b>qu'à 1 seul véhicule.</b><br />
                Merci de bien respecter cette règle pour bénéficier de votre cashback.
              </p>
            </div>

            <!-- Booster explanation block -->
            <BoosterExplanation class="block-booster-explanation"></BoosterExplanation>

            <!-- Contest block -->
            <Concours class="block-concours"></Concours>

            <sav-block class="block-sav-mobile" />
          </div>

          <div>
            <!-- Infos block -->
            <WinDirhamExplanation />

            <sav-block class="block-sav-desktop" />
          </div>
        </div>
      </div>
    </MenuDesktop>
    <FooterSmall />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DirhamCounter from '@/components/DirhamCounter.vue'
import Concours from '@/components/Concours.vue'
import BoosterExplanation from '@/components/BoosterExplanation.vue'
import WinDirhamExplanation from '@/components/WinDirhamExplanation.vue'
import SAVBlock from '@/components/SAVBlock.vue'
import MenuDesktop from '@/components/MenuDesktop.vue'
import { config } from '../config'

export default {
  data () {
    return {
      cashbackClaimEnabled: config.cashbackClaimEnabled,
      isJaugeLoading: false
    }
  },
  components: {
    SavBlock: SAVBlock,
    DirhamCounter,
    WinDirhamExplanation,
    Concours,
    BoosterExplanation,
    MenuDesktop
  },
  computed: {
    ...mapGetters([
      'dirhamsToUnlock',
      'cashback',
      'status',
      'firstName',
      'isCashing',
      'cashingAmount',
      'cardNum',
      'dirhamsExtracting',
      'statusID',
      'isCar',
      'isTaxi',
      'isWarned',
      'isWinAllowed',
      'prepaidCardNum',
      'prePaidCardClaimed',
      'hasAlreadyClaimPrepaidCard',
      'hasAlreadyClaimFirstPrepaidCard',
      'isPrepaidCardClaimOnDelivery'
    ])
  }
}
</script>

<style lang="scss" scoped>
.grid-container {
  max-width: 100%; // Fix mobile
  display: flex;
  flex-direction: column;
  .blocks {
    display: flex;
    flex-direction: column-reverse;
  }
  @include media('>=tablet') {
    padding: 20px 25px;
    .blocks {
      flex-direction: row;
      &>* {
        flex: 1;
        &:last-child {
          margin-left: 25px;
        }
      }
    }
    .jauge-concours {
      display: flex;
      flex-direction: column;
      &>* {
        &:not(:first-child) {
          margin-top: 25px;
        }
      }
    }

    section {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
  @include media('>desktop') {
    padding: 20px 50px;
    .blocks {
      &>* {
        &:last-child {
          margin-left: 50px;
        }
      }
    }
    .jauge-concours {
      &>* {
        &:last-child {
          margin-top: 50px;
        }
      }
    }
    section {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    }
  }
}

.jauge-rules {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 8px 16px;
  margin-top: 0 !important;
  background-color: #164194;

  .jauge-rules-warning {
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }

  .jauge-rules-text {
    font-size: 15px;
    text-align: center;
    color: #fff;
  }

  @include media('>desktop') {
    padding: 12px 32px;

    .jauge-rules-warning {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
  }
}

.block-concours {
  order: 3;
}
.dashboard-page {
  .section-header {
    @include media('>=tablet') {
      box-shadow: none;
      .container {
        padding: 15px 0px 5px;
        h1 {
          color: $red;
        }
      }
    }
    @include media('>=desktop') {
      margin: 0;
      .container {
        max-width: 100%;
        display: flex;
        padding: 25px 0px 40px;
        h1 {
          white-space: nowrap;
        }
        .tagline {
          text-align: left;
          margin: 0 50px;
        }
      }
    }
  }
  @include media('>=tablet') {
    background-color: $white;
    color: $grey;
  }
  .profile {
    margin-bottom: 50px;
    @include media('>=tablet') {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      background-color: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      font-size: 20px;
      color: #df0d36;
      padding: 25px 20px 25px 70px;
      position: relative;
      white-space: nowrap;
      font-family: "Nunito", sans-serif;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
  }
  .profile-auto {
    span {
      &:after {
        left: -45px;
        background-image: url('~@/assets/svg/profile-auto.svg');
      }
    }
  }
  .profile-pro {
    span {
      &:after {
        left: -30px;
        background-image: url('~@/assets/svg/profile-pro.svg');
      }
    }
  }
  .profile-taxi {
    span {
      padding-left: 100px;
      padding-right: 50px;
      &:after {
        left: -45px;
        background-image: url('~@/assets/svg/profile-taxi.svg');
      }
    }
  }
}

.section-jauge {
  background-color: #fff;
  color: $grey;
  margin: 0;
  .jauge {
    margin-bottom: 25px;
  }
  h2 {
    color: $blue;
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    text-transform: uppercase;
  }
  .jauge-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
      display: flex;
      margin: 0 20px 20px;
      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 5px;
      }
    }
  }
  .legend-bloque:before {
    background: #4A96D2;
  }
  .legend-dirhams:before {
    background: $blue;
  }
  .statut {
    text-align: center;

    .status-link {
      color: $grey;
      background: none;
      border: 0;
      display: inline-block;
      font-size: 18px;
      text-decoration: underline;
    }
  }
  @include media('>=tablet') {
    .container {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
}

.cashing {
  text-align: center;
  color: $blue;
  font-size: 18px;
  margin-bottom: 25px;
}

.soon-claim-dirham {
  padding: 20px;
  margin: 0 auto;
  max-width: 360px;
  text-align: center;
}

.block-sav-mobile {
  display: flex;

  @include media('>=tablet') {
    order: 4;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }

  @include media(">desktop") {
    display: none;
  }
}

.block-sav-desktop {
  display: none;

  @include media(">desktop") {
    display: flex;
    margin-top: 25px;
  }
}
</style>
